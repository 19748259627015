.controls[data-v-fb08d5a4] {
  position: absolute;
  display: flex;
  z-index: 9996;
  outline: 1px solid var(--border-color);
  background: var(--primary-background-color);
  left: -1px;
  color: var(--font-color);
  font-size: 12px;
  pointer-events: all;
  width: calc(100% + 2px);
}
.controls.down[data-v-fb08d5a4] {
  bottom: -3px;
}
.controls.up[data-v-fb08d5a4] {
  top: 0;
}
.controls .control[data-v-fb08d5a4] {
  width: 100%;
  max-width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.controls .control.disabled[data-v-fb08d5a4] {
  color: var(--button-disabled);
}
.controls .control[data-v-fb08d5a4]:not(.disabled):hover {
  background: var(--ternary-background-color);
}