label[data-v-6732c24f] {
  display: flex;
  flex-direction: column;
  width: 100%;
}
label.vue-label.testa-select[data-v-6732c24f] {
  min-height: 30px;
}
label.vue-label.testa-select span.label-text[data-v-6732c24f] {
  padding: 0 5px;
  margin-left: 8px;
  margin-bottom: 2px;
  font-size: 10px;
}
label.vue-label.testa-select.for-table[data-v-6732c24f] {
  min-height: auto;
}
label.vue-label.testa-select.for-header[data-v-6732c24f] {
  min-height: auto;
}
label.vue-label.testa-select.for-report-filter[data-v-6732c24f] {
  min-height: auto;
}
label:not(.invalid) span.label-text[data-v-6732c24f] {
  color: var(--font-color-secondary);
}
label:not(.invalid) span.label-text.focus[data-v-6732c24f] {
  color: var(--font-color);
}