.proxy-edit[data-v-a792e6bc] {
  display: flex;
  flex-direction: column;
}
.proxy-edit .header[data-v-a792e6bc] {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.proxy-edit .body[data-v-a792e6bc] {
  margin-top: 10px;
}