.header[data-v-7ef64094] {
  display: flex;
  flex-direction: row;
  padding: 0;
}
.header .scenarios-count[data-v-7ef64094] {
  flex-shrink: 0;
  width: 50px;
  color: var(--font-color-secondary);
  font-size: 13px;
  text-align: left;
}
.header .title[data-v-7ef64094] {
  text-align: center;
  font-size: 25px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.header .modal-complexity-switcher[data-v-7ef64094] {
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  white-space: nowrap;
}
#play_modal .body .grouping[data-v-7ef64094] {
  margin-bottom: 20px;
}
#play_modal .body .row[data-v-7ef64094] {
  margin-bottom: 10px;
  position: relative;
}
#play_modal .body .row.label-row[data-v-7ef64094] {
  margin-bottom: 3px;
  width: calc(100% - 45px);
  margin-left: 0;
}
#play_modal .body .row.label-row div.col[data-v-7ef64094] {
  margin: 0;
}
#play_modal .body .row .reduced-inline-row[data-v-7ef64094] {
  width: calc(100% - 45px);
  display: flex;
  flex-direction: row;
}
#play_modal .body .row .row-save[data-v-7ef64094] {
  width: 30px;
  font-size: 0.95em;
  display: flex;
  justify-content: center;
  align-items: center;
}
#play_modal .body .row .advanced-item[data-v-7ef64094] {
  display: none;
}
#play_modal .body .row .side-item-container[data-v-7ef64094] {
  display: none;
  position: absolute;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  font-size: 1.4em;
  align-items: center;
  width: 40px;
  right: -40px;
}
#play_modal .body .row .side-item-container .side-item[data-v-7ef64094] {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
}
#play_modal .body .row .side-item-container .side-item.add[data-v-7ef64094] {
  color: var(--button-green);
  margin-top: 30px;
}
#play_modal .body .row .side-item-container .side-item.remove[data-v-7ef64094] {
  margin-top: 17px;
  color: var(--button-red);
}
#play_modal .body .row.play-settings div.col[data-v-7ef64094] {
  min-height: 55px;
  align-items: end;
}
#play_modal .body .row.play-settings .schedule-settings[data-v-7ef64094] {
  flex-direction: row;
  align-items: center;
}
#play_modal .body .row.play-settings .schedule-settings div.schedule-setting-item[data-v-7ef64094] {
  margin-inline: 5px;
  width: 100%;
}
#play_modal .body .row.play-settings .worker-settings[data-v-7ef64094] {
  padding-top: 10px;
  padding-inline: 0;
}
#play_modal .body .row.play-settings .worker-settings div.col[data-v-7ef64094] {
  min-height: 0;
}
#play_modal .body .edit-variable-set[data-v-7ef64094] {
  cursor: pointer;
  aspect-ratio: 1;
  display: flex;
  margin-top: 18px;
  font-size: 1.3em;
}
#play_modal .body.advanced-play .row[data-v-7ef64094] {
  margin-right: 40px;
}
#play_modal .body.advanced-play .row .side-item-container[data-v-7ef64094] {
  display: flex;
}
#play_modal .body.advanced-play .row .advanced-item[data-v-7ef64094] {
  display: flex;
}
.curl-request[data-v-7ef64094] {
  color: var(--font-color-secondary);
  font-size: 0.6em;
  cursor: pointer;
  align-self: end;
}
.first-row-label-padding[data-v-7ef64094] {
  padding-top: 14px;
}
.browser-is-mobile-toggle[data-v-7ef64094],
.mobile-type-toggle[data-v-7ef64094] {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
  font-size: 1.3em;
}