.resizable-flex[data-v-5efc97e2] {
  display: flex;
}
.resizable-flex .area[data-v-5efc97e2] {
  overflow: auto;
  display: flex;
}
.resizable-flex .stick[data-v-5efc97e2] {
  background-color: var(--secondary-background-color);
}
.resizable-flex.vertical[data-v-5efc97e2] {
  height: 100%;
}
.resizable-flex.vertical .area[data-v-5efc97e2] {
  min-height: 0;
}
.resizable-flex.vertical .stick[data-v-5efc97e2] {
  width: 100%;
  cursor: ns-resize;
}
.resizable-flex.horizontal[data-v-5efc97e2] {
  width: 100%;
}
.resizable-flex.horizontal .area[data-v-5efc97e2] {
  min-width: 0;
}
.resizable-flex.horizontal .stick[data-v-5efc97e2] {
  height: 100%;
  cursor: ew-resize;
}