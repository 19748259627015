label.invalid span.label-text {
  color: var(--button-red);
}
label.invalid .select2-container--default .select2-selection--multiple,
label.invalid .select2-container--default .select2-selection--single {
  border-color: var(--button-red);
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}
.select2-container .select2-selection {
  background-color: var(--ternary-background-color);
  /* Show arrow down icon */
}
.select2-container .select2-selection::before {
  content: "";
  position: absolute;
  right: 7px;
  top: 42%;
  border-top: 5px solid var(--font-color-secondary);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  user-select: none;
  -webkit-user-select: none;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
  padding-left: 5px;
  padding-top: 3px;
  border: 1px solid var(--secondary-background-color);
  border-radius: 5px;
}
.select2-container .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  color: var(--font-color);
  border-color: var(--secondary-background-color);
  border-radius: 5px;
  padding: 3px;
  font-size: 14px;
  width: calc(100% - 15px);
}
.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
  background-color: var(--ternary-background-color);
  border: 1px solid var(--secondary-background-color);
  border-radius: 5px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}
.select2-container .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
  font-size: 14px;
}
.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}
.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: transparent;
  border: 1px solid var(--secondary-background-color);
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0 0 0 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  line-height: 1.75em;
}
.select2-container .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
  vertical-align: text-top;
  display: flex;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: var(--button-red);
  color: var(--font-color);
  outline: none;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
  color: var(--font-color);
  background: transparent;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-container .select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-container .select2-search--dropdown .select2-search__field {
  padding: 2px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--font-color-secondary);
  border-radius: 5px;
}
.select2-container .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-container .select2-search--dropdown input.select2-search__field[tabindex]:not(:focus-visible):not(:focus) {
  border: 1px solid var(--button-grey);
}
.select2-container .select2-search--dropdown.select2-search--hide {
  display: none;
}
.select2-container .select2-results {
  display: block;
}
.select2-container .select2-results > .select2-results__options {
  float: left;
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 4px;
  max-height: 200px;
  width: calc(100% - 7px);
  text-align: left;
  overflow-y: auto;
}
.select2-container .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container .select2-results__option {
  overflow-y: hidden;
  /*  height: 12px;*/
  min-height: 1.2em;
  width: 100%;
  font-size: 14px;
  float: left;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container .select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-container .select2-results__option--disabled {
  color: #999;
}
.select2-container .select2-results__option--highlighted.select2-results__option--selectable {
  color: var(--font-color-hover);
  filter: brightness(1.2);
  cursor: pointer;
  background-color: var(--confirm-color);
}
.select2-container .select2-results__option--selectable {
  /* changed  */
  cursor: default;
}
.select2-container .select2-results__option--selected {
  color: white;
  background-color: var(--ternary-background-color);
}
.select2-container .select2-results__option--group {
  padding: 0;
}
.select2-container .select2-results__option--group .select2-results__options--nested {
  overflow-x: hidden;
}
.select2-container .select2-results__option--group .select2-results__options--nested li {
  overflow-x: hidden;
}
.select2-container .select2-dropdown {
  background-color: var(--ternary-background-color);
  border: 1px solid var(--font-color);
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}
.select2-container.select2-container--open {
  z-index: inherit;
}
.select2-container.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container.select2-container--open.select2-container--above .select2-selection--single,
.select2-container.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container.select2-container--open.select2-container--below .select2-selection--single,
.select2-container.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container.select2-container--open .select2-selection--single {
  border: solid var(--font-color) 1px;
  outline: 0;
}
.select2-container.select2-container--open .select2-selection--single::before {
  content: "";
  position: absolute;
  right: 7px;
  top: 42%;
  border-top: 5px solid var(--font-color);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.select2-container.select2-container--disabled .select2-selection--single {
  filter: opacity(0.5);
  color: var(--font-color-secondary);
  cursor: default;
}
.select2-container.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container.select2-container--focus .select2-selection--single,
.select2-container.select2-container--focus .select2-selection--multiple {
  border: solid var(--focus-color) 1px;
  outline: 0;
}
.select2-container.select2-container--focus .select2-selection--single::before,
.select2-container.select2-container--focus .select2-selection--multiple::before {
  border-top: 5px solid var(--font-color-secondary);
}
.select2-container:not(.select2-container--disabled):hover {
  filter: brightness(1.1);
}
.select2-container--table .select2-selection {
  /* Show arrow down icon */
}
.select2-container--table .select2-selection::before {
  right: 5.25px;
  border-top-width: 3.75px;
  border-left-width: 3px;
  border-right-width: 3px;
}
.select2-container--table .select2-selection--single {
  height: 22.5px;
  padding-left: 3.75px;
  padding-top: 2.25px;
}
.select2-container--table .select2-selection--single .select2-selection__rendered {
  padding: 2.25px;
  font-size: 11.9px;
  width: calc(100% - 11.25px);
}
.select2-container--table .select2-selection--single .select2-selection__clear {
  height: 19.5px;
  margin-right: 15px;
}
.select2-container--table .select2-selection--single .select2-search__field {
  font-size: 13.6px !important;
  margin-bottom: 6px !important;
}
.select2-container--table .select2-selection--multiple {
  min-height: 24px;
  padding-bottom: 3.75px;
  padding-right: 3.75px;
}
.select2-container--table .select2-selection--multiple .select2-selection__rendered {
  font-size: 11.9px;
}
.select2-container--table .select2-selection--multiple.select2-selection--clearable {
  padding-right: 18.75px;
}
.select2-container--table .select2-selection--multiple .select2-selection__clear {
  height: 15px;
  margin-right: 7.5px;
  margin-top: 3.75px;
  padding: 0.75px;
}
.select2-container--table .select2-selection--multiple .select2-selection__choice {
  margin-left: 3.75px;
  margin-top: 3.75px;
  padding: 0 0 0 15px;
}
.select2-container--table .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 1.5px;
  padding-right: 3.75px;
}
.select2-container--table .select2-selection--multiple .select2-selection__choice__remove {
  padding: 0 3px;
}
.select2-container--table .select2-selection--multiple .select2-search__field {
  font-size: 10.2px !important;
  margin-bottom: 3px !important;
}
.select2-container--table .select2-search--inline .select2-search__field {
  margin-top: 3.75px;
  margin-left: 3.75px;
  height: 13.5px;
}
.select2-container--table .select2-search--dropdown {
  padding: 3px;
}
.select2-container--table .select2-search--dropdown .select2-search__field {
  padding: 3px;
}
.select2-container--table .select2-results > .select2-results__options {
  padding-left: 3.75px;
  padding-top: 1.5px;
  padding-bottom: 3px;
  max-height: 150px;
  width: calc(100% - 5.25px);
}
.select2-container--table .select2-results__group {
  padding: 4.5px;
}
.select2-container--table .select2-results__option {
  font-size: 11.9px;
}
.select2-container--table.select2-container--open .select2-selection--single::before {
  right: 5.25px;
  border-top-width: 3.75px;
  border-left-width: 3px;
  border-right-width: 3px;
}
.select2-container--table.select2-container--focus .select2-selection--single::before,
.select2-container--table.select2-container--focus .select2-selection--multiple::before {
  border-top-width: 3.75px;
}
.select2-container--header .select2-selection {
  /* Show arrow down icon */
}
.select2-container--header .select2-selection::before {
  right: 6.02px;
  border-top-width: 4.3px;
  border-left-width: 3.44px;
  border-right-width: 3.44px;
}
.select2-container--header .select2-selection--single {
  background-color: var(--secondary-background-color);
  height: 25.8px;
  padding-left: 4.3px;
  padding-top: 2.58px;
}
.select2-container--header .select2-selection--single .select2-selection__rendered {
  padding: 2.58px;
  font-size: 12.04px;
  width: calc(100% - 12.9px);
}
.select2-container--header .select2-selection--single .select2-selection__clear {
  height: 22.36px;
  margin-right: 17.2px;
}
.select2-container--header .select2-selection--single .select2-search__field {
  font-size: 13.76px !important;
  margin-bottom: 6.88px !important;
}
.select2-container--header .select2-selection--multiple {
  background-color: var(--secondary-background-color);
  min-height: 27.52px;
  padding-bottom: 4.3px;
  padding-right: 4.3px;
}
.select2-container--header .select2-selection--multiple .select2-selection__rendered {
  font-size: 12.04px;
}
.select2-container--header .select2-selection--multiple.select2-selection--clearable {
  padding-right: 21.5px;
}
.select2-container--header .select2-selection--multiple .select2-selection__clear {
  height: 17.2px;
  margin-right: 8.6px;
  margin-top: 4.3px;
  padding: 0.86px;
}
.select2-container--header .select2-selection--multiple .select2-selection__choice {
  margin-left: 4.3px;
  margin-top: 4.3px;
  padding: 0 0 0 17.2px;
}
.select2-container--header .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 1.72px;
  padding-right: 4.3px;
}
.select2-container--header .select2-selection--multiple .select2-selection__choice__remove {
  padding: 0 3.44px;
}
.select2-container--header .select2-selection--multiple .select2-search__field {
  font-size: 10.32px !important;
  margin-bottom: 3.44px !important;
}
.select2-container--header .select2-search--inline .select2-search__field {
  margin-top: 4.3px;
  margin-left: 4.3px;
  height: 15.48px;
}
.select2-container--header .select2-search--dropdown {
  padding: 3.44px;
}
.select2-container--header .select2-search--dropdown .select2-search__field {
  padding: 3.44px;
}
.select2-container--header .select2-results > .select2-results__options {
  padding-left: 4.3px;
  padding-top: 1.72px;
  padding-bottom: 3.44px;
  max-height: 172px;
  width: calc(100% - 6.02px);
}
.select2-container--header .select2-results__group {
  padding: 5.16px;
}
.select2-container--header .select2-results__option {
  font-size: 12.04px;
}
.select2-container--header.select2-container--open .select2-selection--single::before {
  right: 6.02px;
  border-top-width: 4.3px;
  border-left-width: 3.44px;
  border-right-width: 3.44px;
}
.select2-container--header.select2-container--focus .select2-selection--single::before,
.select2-container--header.select2-container--focus .select2-selection--multiple::before {
  border-top-width: 4.3px;
}
.select2-container--report-filter .select2-selection {
  /* Show arrow down icon */
}
.select2-container--report-filter .select2-selection::before {
  right: 5.25px;
  border-top-width: 3.75px;
  border-left-width: 3px;
  border-right-width: 3px;
}
.select2-container--report-filter .select2-selection--single {
  height: 22.5px;
  padding-left: 3.75px;
  padding-top: 2.25px;
}
.select2-container--report-filter .select2-selection--single .select2-selection__rendered {
  padding: 2.25px;
  font-size: 12.6px;
  width: calc(100% - 11.25px);
}
.select2-container--report-filter .select2-selection--single .select2-selection__clear {
  height: 19.5px;
  margin-right: 15px;
}
.select2-container--report-filter .select2-selection--single .select2-search__field {
  font-size: 14.4px !important;
  margin-bottom: 6px !important;
}
.select2-container--report-filter .select2-selection--multiple {
  min-height: 24px;
  padding-bottom: 3.75px;
  padding-right: 3.75px;
}
.select2-container--report-filter .select2-selection--multiple .select2-selection__rendered {
  font-size: 12.6px;
}
.select2-container--report-filter .select2-selection--multiple.select2-selection--clearable {
  padding-right: 18.75px;
}
.select2-container--report-filter .select2-selection--multiple .select2-selection__clear {
  height: 15px;
  margin-right: 7.5px;
  margin-top: 3.75px;
  padding: 0.75px;
}
.select2-container--report-filter .select2-selection--multiple .select2-selection__choice {
  margin-left: 3.75px;
  margin-top: 3.75px;
  padding: 0 0 0 15px;
}
.select2-container--report-filter .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 1.5px;
  padding-right: 3.75px;
}
.select2-container--report-filter .select2-selection--multiple .select2-selection__choice__remove {
  padding: 0 3px;
}
.select2-container--report-filter .select2-selection--multiple .select2-search__field {
  font-size: 10.8px !important;
  margin-bottom: 3px !important;
}
.select2-container--report-filter .select2-search--inline .select2-search__field {
  margin-top: 3.75px;
  margin-left: 3.75px;
  height: 13.5px;
}
.select2-container--report-filter .select2-search--dropdown {
  padding: 3px;
}
.select2-container--report-filter .select2-search--dropdown .select2-search__field {
  padding: 3px;
}
.select2-container--report-filter .select2-results > .select2-results__options {
  padding-left: 3.75px;
  padding-top: 1.5px;
  padding-bottom: 3px;
  max-height: 150px;
  width: calc(100% - 5.25px);
}
.select2-container--report-filter .select2-results__group {
  padding: 4.5px;
}
.select2-container--report-filter .select2-results__option {
  font-size: 12.6px;
}
.select2-container--report-filter.select2-container--open .select2-selection--single::before {
  right: 5.25px;
  border-top-width: 3.75px;
  border-left-width: 3px;
  border-right-width: 3px;
}
.select2-container--report-filter.select2-container--focus .select2-selection--single::before,
.select2-container--report-filter.select2-container--focus .select2-selection--multiple::before {
  border-top-width: 3.75px;
}
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}