.title-container[data-v-13a4291d] {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  padding-top: 2px;
  padding-bottom: 2px;
}
.title-container .title-primary-info[data-v-13a4291d] {
  display: flex;
  flex-direction: row;
  font-size: 1.1em;
  align-items: baseline;
}
.title-container .title-primary-info .play-type-icon-container[data-v-13a4291d] {
  font-size: 0.9em;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.title-container .title-primary-info div.name[data-v-13a4291d] {
  color: var(--font-color);
  margin-right: 5px;
  white-space: nowrap;
  flex-shrink: 0;
}
.title-container .title-primary-info div.name span.highlight[data-v-13a4291d] {
  background: var(--sidebar-selected-contrast);
}
.title-container .title-secondary-info[data-v-13a4291d] {
  color: var(--font-color-secondary);
  font-size: 0.9em;
}