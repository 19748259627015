.section-toggler[data-v-6b7ebb73] {
  display: flex;
  flex-direction: row;
  width: calc(100% - 2px);
  flex-shrink: 0;
  height: 25px;
  border-bottom: 1px solid var(--secondary-background-color);
}
.section-toggler.vertical[data-v-6b7ebb73] {
  flex-direction: column;
  height: calc(100% - 2px);
  width: 25px;
}
.section-toggler.vertical.left[data-v-6b7ebb73] {
  border-right: 1px solid var(--secondary-background-color);
}
.section-toggler.vertical.right[data-v-6b7ebb73] {
  border-left: 1px solid var(--secondary-background-color);
}
.section-toggler .section[data-v-6b7ebb73] {
  background-color: var(--primary-background-color);
  padding-inline: 8px;
  font-size: 0.8em;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-toggler .section[data-v-6b7ebb73]:hover {
  filter: brightness(1.2);
}
.section-toggler .section.enabled[data-v-6b7ebb73] {
  background-color: var(--secondary-background-color);
}
.section-toggler .section.vertical.left[data-v-6b7ebb73] {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg); /* Safari */
  -moz-transform: rotate(180deg); /* Firefox */
  -ms-transform: rotate(180deg); /* IE */
  -o-transform: rotate(180deg); /* Opera */
}
.section-toggler .section.vertical.right[data-v-6b7ebb73] {
  writing-mode: vertical-lr;
}