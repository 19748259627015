.loading-wrapper[data-v-65c14d58] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-inline: 5px;
}
.loading-wrapper.inflate[data-v-65c14d58] {
  width: 100%;
  height: 100%;
  flex-shrink: 999;
}
@keyframes fadeInUp-65c14d58 {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}
.loading-wrapper.fadein[data-v-65c14d58] {
  opacity: 0;
  animation: fadeInUp-65c14d58 1s ease-in-out 0s forwards;
}
.loading-wrapper .coloring_before[data-v-65c14d58]::before {
  border: 0.03em solid var(--primary-background-color);
  background-color: var(--secondary-background-color);
}
.loading-wrapper .coloring_before.primary[data-v-65c14d58]::before {
  border: 0.03em solid var(--secondary-background-color);
  background-color: var(--primary-background-color);
}
.loading-wrapper .coloring_before.default[data-v-65c14d58]::before {
  background-color: var(--secondary-background-color);
}
.loading-wrapper .coloring_before.grey[data-v-65c14d58]::before {
  background-color: var(--button-grey);
}
.loading-wrapper .coloring_before.red[data-v-65c14d58]::before {
  background-color: var(--button-red);
}
.loading-wrapper .coloring_before.green[data-v-65c14d58]::before {
  background-color: var(--button-green);
}
.loading-wrapper .coloring_before.blue[data-v-65c14d58]::before {
  background-color: var(--button-blue);
}
.loading-wrapper .coloring_before.white[data-v-65c14d58]::before {
  background-color: var(--button-white) !important;
}
.loading-wrapper .coloring[data-v-65c14d58] {
  border: 0.03em solid var(--primary-background-color);
  background-color: var(--secondary-background-color);
}
.loading-wrapper .coloring.primary[data-v-65c14d58] {
  background-color: var(--primary-background-color);
  border: 0.03em solid var(--secondary-background-color);
}
.loading-wrapper .coloring.default[data-v-65c14d58] {
  background-color: var(--secondary-background-color);
}
.loading-wrapper .coloring.grey[data-v-65c14d58] {
  background-color: var(--button-grey);
}
.loading-wrapper .coloring.red[data-v-65c14d58] {
  background-color: var(--button-red);
}
.loading-wrapper .coloring.green[data-v-65c14d58] {
  background-color: var(--button-green);
}
.loading-wrapper .coloring.blue[data-v-65c14d58] {
  background-color: var(--button-blue);
}
.loading-wrapper .coloring.white[data-v-65c14d58] {
  background-color: var(--button-white) !important;
}
.loading-wrapper .rotating-plane[data-v-65c14d58] {
  width: 1.0233333333em;
  aspect-ratio: 1/1;
  animation: rotating-plane-65c14d58 1.2s infinite ease-in-out;
}
@keyframes rotating-plane-65c14d58 {
0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
}
50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
}
100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
}
}
.loading-wrapper .double-bounce[data-v-65c14d58] {
  width: 1.3em;
  aspect-ratio: 1/1;
  position: relative;
  margin: auto;
}
.loading-wrapper .double-bounce .child[data-v-65c14d58] {
  width: calc(100% - 0.06em);
  aspect-ratio: 1/1;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: double-bounce-65c14d58 2s infinite ease-in-out;
}
.loading-wrapper .double-bounce .double-bounce-2[data-v-65c14d58] {
  animation-delay: -1s;
}
@keyframes double-bounce-65c14d58 {
0%, 100% {
    transform: scale(0);
}
50% {
    transform: scale(1);
}
}
.loading-wrapper .wave[data-v-65c14d58] {
  width: 1.95em;
  height: 1.3em;
  margin: auto;
  text-align: center;
  font-size: 1em;
}
.loading-wrapper .wave .rect[data-v-65c14d58] {
  height: calc(100% - 0.06em);
  aspect-ratio: 1/9;
  display: inline-block;
  animation: wave-stretch-delay-65c14d58 1.2s infinite ease-in-out;
}
.loading-wrapper .wave .rect-1[data-v-65c14d58] {
  animation-delay: -1.2s;
}
.loading-wrapper .wave .rect-2[data-v-65c14d58] {
  animation-delay: -1.3s;
}
.loading-wrapper .wave .rect-3[data-v-65c14d58] {
  animation-delay: -1.4s;
}
.loading-wrapper .wave .rect-4[data-v-65c14d58] {
  animation-delay: -1.5s;
}
.loading-wrapper .wave .rect-5[data-v-65c14d58] {
  animation-delay: -1.6s;
}
@keyframes wave-stretch-delay-65c14d58 {
0%, 40%, 100% {
    transform: scaleY(0.4);
}
20% {
    transform: scaleY(1);
}
}
.loading-wrapper .wandering-cubes[data-v-65c14d58] {
  width: 1.3em;
  aspect-ratio: 1/1;
  position: relative;
  margin: auto;
}
.loading-wrapper .wandering-cubes .cube[data-v-65c14d58] {
  width: 0.31em;
  aspect-ratio: 1/1;
  position: absolute;
  top: 0;
  left: 0;
  animation: wandering-cubes-65c14d58 1.8s ease-in-out -1.8s infinite both;
}
.loading-wrapper .wandering-cubes .cube-2[data-v-65c14d58] {
  animation-delay: -0.9s;
}
@keyframes wandering-cubes-65c14d58 {
0% {
    transform: rotate(0deg);
}
25% {
    transform: translateX(0.62em) rotate(-90deg) scale(0.5);
}
50% {
    /* Hack to make FF rotate in the right direction */
    transform: translateX(0.62em) translateY(0.62em) rotate(-179deg);
}
50.1% {
    transform: translateX(0.62em) translateY(0.62em) rotate(-180deg);
}
75% {
    transform: translateX(0) translateY(0.62em) rotate(-270deg) scale(0.5);
}
100% {
    transform: rotate(-360deg);
}
}
.loading-wrapper .spinner-pulse[data-v-65c14d58] {
  width: 1.24em;
  aspect-ratio: 1/1;
  margin: auto;
  border-radius: 100%;
  animation: spinner-pulse-65c14d58 1.5s infinite ease-in-out;
  box-shadow: var(--secondary-background-color) 0 0 0 0;
}
@keyframes spinner-pulse-65c14d58 {
0% {
    transform: scale(0);
    box-shadow: rgba(255, 105, 180, 0) 0 0 50px 100px;
    opacity: 0.75;
}
100% {
    transform: scale(1);
    box-shadow: var(--secondary-background-color) 0 0 0 0;
    opacity: 0;
}
}
.loading-wrapper .chasing-dots[data-v-65c14d58] {
  width: 1.3em;
  aspect-ratio: 1/1;
  position: relative;
  margin: auto;
  text-align: center;
  animation: chasing-dots-rotate-65c14d58 2s infinite linear;
}
.loading-wrapper .chasing-dots .child[data-v-65c14d58] {
  width: 0.62em;
  aspect-ratio: 1/1;
  display: inline-block;
  position: absolute;
  top: 0;
  border-radius: 100%;
  animation: chasing-dots-bounce-65c14d58 2s infinite ease-in-out;
}
.loading-wrapper .chasing-dots .dot-2[data-v-65c14d58] {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}
@keyframes chasing-dots-rotate-65c14d58 {
100% {
    transform: rotate(360deg);
}
}
@keyframes chasing-dots-bounce-65c14d58 {
0%, 100% {
    transform: scale(0);
}
50% {
    transform: scale(1);
}
}
.loading-wrapper .three-bounce[data-v-65c14d58] {
  width: 2.6em;
  margin: auto;
  text-align: center;
}
.loading-wrapper .three-bounce .child[data-v-65c14d58] {
  width: 0.62em;
  aspect-ratio: 1/1;
  border-radius: 100%;
  display: inline-block;
  animation: three-bounce-65c14d58 1.4s ease-in-out 0s infinite both;
}
.loading-wrapper .three-bounce .bounce-1[data-v-65c14d58] {
  animation-delay: -0.32s;
}
.loading-wrapper .three-bounce .bounce-2[data-v-65c14d58] {
  animation-delay: -0.16s;
}
@keyframes three-bounce-65c14d58 {
0%, 80%, 100% {
    transform: scale(0);
}
40% {
    transform: scale(1);
}
}
.loading-wrapper .circle-bounce[data-v-65c14d58] {
  width: 1.3em;
  aspect-ratio: 1/1;
  position: relative;
  margin: auto;
}
.loading-wrapper .circle-bounce .child[data-v-65c14d58] {
  width: calc(100% - 0.06em);
  aspect-ratio: 1/1;
  position: absolute;
  left: 0;
  top: 0;
}
.loading-wrapper .circle-bounce .child[data-v-65c14d58]:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: calc(15% - 0.06em);
  aspect-ratio: 1/1;
  border-radius: 100%;
  animation: circle-bounce-delay-65c14d58 1.2s infinite ease-in-out both;
}
.loading-wrapper .circle-bounce .circle-2[data-v-65c14d58] {
  transform: rotate(30deg);
}
.loading-wrapper .circle-bounce .circle-3[data-v-65c14d58] {
  transform: rotate(60deg);
}
.loading-wrapper .circle-bounce .circle-4[data-v-65c14d58] {
  transform: rotate(90deg);
}
.loading-wrapper .circle-bounce .circle-5[data-v-65c14d58] {
  transform: rotate(120deg);
}
.loading-wrapper .circle-bounce .circle-6[data-v-65c14d58] {
  transform: rotate(150deg);
}
.loading-wrapper .circle-bounce .circle-7[data-v-65c14d58] {
  transform: rotate(180deg);
}
.loading-wrapper .circle-bounce .circle-8[data-v-65c14d58] {
  transform: rotate(210deg);
}
.loading-wrapper .circle-bounce .circle-9[data-v-65c14d58] {
  transform: rotate(240deg);
}
.loading-wrapper .circle-bounce .circle-10[data-v-65c14d58] {
  transform: rotate(270deg);
}
.loading-wrapper .circle-bounce .circle-11[data-v-65c14d58] {
  transform: rotate(300deg);
}
.loading-wrapper .circle-bounce .circle-12[data-v-65c14d58] {
  transform: rotate(330deg);
}
.loading-wrapper .circle-bounce .circle-2[data-v-65c14d58]:before {
  animation-delay: -1.1s;
}
.loading-wrapper .circle-bounce .circle-3[data-v-65c14d58]:before {
  animation-delay: -1s;
}
.loading-wrapper .circle-bounce .circle-4[data-v-65c14d58]:before {
  animation-delay: -0.9s;
}
.loading-wrapper .circle-bounce .circle-5[data-v-65c14d58]:before {
  animation-delay: -0.8s;
}
.loading-wrapper .circle-bounce .circle-6[data-v-65c14d58]:before {
  animation-delay: -0.7s;
}
.loading-wrapper .circle-bounce .circle-7[data-v-65c14d58]:before {
  animation-delay: -0.6s;
}
.loading-wrapper .circle-bounce .circle-8[data-v-65c14d58]:before {
  animation-delay: -0.5s;
}
.loading-wrapper .circle-bounce .circle-9[data-v-65c14d58]:before {
  animation-delay: -0.4s;
}
.loading-wrapper .circle-bounce .circle-10[data-v-65c14d58]:before {
  animation-delay: -0.3s;
}
.loading-wrapper .circle-bounce .circle-11[data-v-65c14d58]:before {
  animation-delay: -0.2s;
}
.loading-wrapper .circle-bounce .circle-12[data-v-65c14d58]:before {
  animation-delay: -0.1s;
}
@keyframes circle-bounce-delay-65c14d58 {
0%, 80%, 100% {
    transform: scale(0);
}
40% {
    transform: scale(1);
}
}
.loading-wrapper .cube-grid[data-v-65c14d58] {
  width: 1.3em;
  aspect-ratio: 1/1;
  margin: auto;
  /*
   * Spinner positions
   * 1 2 3
   * 4 5 6
   * 7 8 9
   */
}
.loading-wrapper .cube-grid .cube[data-v-65c14d58] {
  width: calc(33.3334% - 0.12em);
  aspect-ratio: 1/1;
  float: left;
  animation: cube-grid-scale-delay-65c14d58 1.3s infinite ease-in-out;
}
.loading-wrapper .cube-grid .cube-1[data-v-65c14d58] {
  animation-delay: 0.2s;
}
.loading-wrapper .cube-grid .cube-2[data-v-65c14d58] {
  animation-delay: 0.3s;
}
.loading-wrapper .cube-grid .cube-3[data-v-65c14d58] {
  animation-delay: 0.4s;
}
.loading-wrapper .cube-grid .cube-4[data-v-65c14d58] {
  animation-delay: 0.1s;
}
.loading-wrapper .cube-grid .cube-5[data-v-65c14d58] {
  animation-delay: 0.2s;
}
.loading-wrapper .cube-grid .cube-6[data-v-65c14d58] {
  animation-delay: 0.3s;
}
.loading-wrapper .cube-grid .cube-7[data-v-65c14d58] {
  animation-delay: 0s;
}
.loading-wrapper .cube-grid .cube-8[data-v-65c14d58] {
  animation-delay: 0.1s;
}
.loading-wrapper .cube-grid .cube-9[data-v-65c14d58] {
  animation-delay: 0.2s;
}
@keyframes cube-grid-scale-delay-65c14d58 {
0%, 70%, 100% {
    transform: scale3D(1, 1, 1);
}
35% {
    transform: scale3D(0, 0, 1);
}
}
.loading-wrapper .fading-circle[data-v-65c14d58] {
  width: 1.3em;
  aspect-ratio: 1/1;
  position: relative;
  margin: auto;
}
.loading-wrapper .fading-circle .circle[data-v-65c14d58] {
  width: calc(100% - 0.06em);
  aspect-ratio: 1/1;
  position: absolute;
  left: 0;
  top: 0;
}
.loading-wrapper .fading-circle .circle[data-v-65c14d58]:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: calc(20% - 0.06em);
  aspect-ratio: 1/1;
  border-radius: 100%;
  animation: fading-circle-delay-65c14d58 1.6s infinite ease-in-out both;
}
.loading-wrapper .fading-circle .circle-2[data-v-65c14d58] {
  transform: rotate(30deg);
}
.loading-wrapper .fading-circle .circle-3[data-v-65c14d58] {
  transform: rotate(60deg);
}
.loading-wrapper .fading-circle .circle-4[data-v-65c14d58] {
  transform: rotate(90deg);
}
.loading-wrapper .fading-circle .circle-5[data-v-65c14d58] {
  transform: rotate(120deg);
}
.loading-wrapper .fading-circle .circle-6[data-v-65c14d58] {
  transform: rotate(150deg);
}
.loading-wrapper .fading-circle .circle-7[data-v-65c14d58] {
  transform: rotate(180deg);
}
.loading-wrapper .fading-circle .circle-8[data-v-65c14d58] {
  transform: rotate(210deg);
}
.loading-wrapper .fading-circle .circle-9[data-v-65c14d58] {
  transform: rotate(240deg);
}
.loading-wrapper .fading-circle .circle-10[data-v-65c14d58] {
  transform: rotate(270deg);
}
.loading-wrapper .fading-circle .circle-11[data-v-65c14d58] {
  transform: rotate(300deg);
}
.loading-wrapper .fading-circle .circle-12[data-v-65c14d58] {
  transform: rotate(330deg);
}
.loading-wrapper .fading-circle .circle-2[data-v-65c14d58]:before {
  animation-delay: -1.4666666667s;
}
.loading-wrapper .fading-circle .circle-3[data-v-65c14d58]:before {
  animation-delay: -1.3333333333s;
}
.loading-wrapper .fading-circle .circle-4[data-v-65c14d58]:before {
  animation-delay: -1.2s;
}
.loading-wrapper .fading-circle .circle-5[data-v-65c14d58]:before {
  animation-delay: -1.0666666667s;
}
.loading-wrapper .fading-circle .circle-6[data-v-65c14d58]:before {
  animation-delay: -0.9333333333s;
}
.loading-wrapper .fading-circle .circle-7[data-v-65c14d58]:before {
  animation-delay: -0.8s;
}
.loading-wrapper .fading-circle .circle-8[data-v-65c14d58]:before {
  animation-delay: -0.6666666667s;
}
.loading-wrapper .fading-circle .circle-9[data-v-65c14d58]:before {
  animation-delay: -0.5333333333s;
}
.loading-wrapper .fading-circle .circle-10[data-v-65c14d58]:before {
  animation-delay: -0.4s;
}
.loading-wrapper .fading-circle .circle-11[data-v-65c14d58]:before {
  animation-delay: -0.2666666667s;
}
.loading-wrapper .fading-circle .circle-12[data-v-65c14d58]:before {
  animation-delay: -0.1333333333s;
}
@keyframes fading-circle-delay-65c14d58 {
0%, 39%, 100% {
    opacity: 0;
}
40% {
    opacity: 1;
}
}
.loading-wrapper .folding-cube[data-v-65c14d58] {
  width: 1.3em;
  aspect-ratio: 1/1;
  position: relative;
  margin: auto;
  transform: rotateZ(45deg);
}
.loading-wrapper .folding-cube .cube[data-v-65c14d58] {
  float: left;
  width: calc(50% - 0.06em);
  aspect-ratio: 1/1;
  position: relative;
  transform: scale(1.1);
}
.loading-wrapper .folding-cube .cube[data-v-65c14d58]:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 0.06em);
  aspect-ratio: 1/1;
  animation: folding-cube-angle-65c14d58 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.loading-wrapper .folding-cube .cube-2[data-v-65c14d58] {
  transform: scale(1.1) rotateZ(90deg);
}
.loading-wrapper .folding-cube .cube-3[data-v-65c14d58] {
  transform: scale(1.1) rotateZ(180deg);
}
.loading-wrapper .folding-cube .cube-4[data-v-65c14d58] {
  transform: scale(1.1) rotateZ(270deg);
}
.loading-wrapper .folding-cube .cube-2[data-v-65c14d58]:before {
  animation-delay: 0.3s;
}
.loading-wrapper .folding-cube .cube-3[data-v-65c14d58]:before {
  animation-delay: 0.6s;
}
.loading-wrapper .folding-cube .cube-4[data-v-65c14d58]:before {
  animation-delay: 0.9s;
}
@keyframes folding-cube-angle-65c14d58 {
0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
}
25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
}
90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
}
}