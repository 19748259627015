.container[data-v-53caf311] {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.container .help-container[data-v-53caf311] {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: 100%;
}
.container .help-container a[data-v-53caf311] {
  color: var(--button-blue);
}
.container .lint-container[data-v-53caf311] {
  height: 100%;
  width: 100%;
  flex-shrink: 1;
}