.single-item-filter-container[data-v-6f48d002] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.single-item-filter-container .icon[data-v-6f48d002] {
  font-size: 0.7em;
  margin-right: 5px;
}
.single-item-filter-container .name[data-v-6f48d002] {
  font-size: 0.8em;
  white-space: nowrap;
}
.scroll-container[data-v-6f48d002] {
  height: 100%;
  overflow: auto;
  width: 100%;
}
.scroll-container .reports-container[data-v-6f48d002] {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.scroll-container .reports-container .reports-tree-container[data-v-6f48d002] {
  width: 100%;
  overflow: auto;
}
.scroll-container .reports-container .reports-tree-container .no-reports-info[data-v-6f48d002] {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}