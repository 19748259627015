.play-log-container[data-v-5f027579] {
  margin-left: 5px;
  display: flex;
  width: calc(100% - 5px);
  flex-direction: row;
  flex-shrink: 0;
}
.play-log-container .click-item[data-v-5f027579] {
  cursor: pointer;
}
.play-log-container .click-item[data-v-5f027579]:hover {
  color: var(--font-color-hover);
  filter: brightness(1.2);
}
.play-log-container .collapser[data-v-5f027579] {
  display: flex;
  justify-content: center;
  width: 20px;
  flex-shrink: 0;
  font-size: 0.5em;
  padding-top: 5px;
  line-height: 17px;
  padding-bottom: 2px;
  margin-left: -20px;
  color: var(--font-color-secondary);
}
.play-log-container .play-log-content-container[data-v-5f027579] {
  flex-direction: column;
  flex-shrink: 0;
  display: flex;
  width: 100%;
}
.play-log-container .play-log-content-container .message[data-v-5f027579] {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  overflow-wrap: anywhere;
}
.play-log-container .play-log-content-container .message .verbose[data-v-5f027579] {
  color: var(--log-color-verbose);
  display: table-row;
  font-size: 11px;
  line-height: 17px;
  white-space: pre-wrap;
  font-family: monospace;
  letter-spacing: -0.05em;
  margin-right: 5px;
}
.play-log-container .play-log-content-container .message .info[data-v-5f027579] {
  color: var(--log-color-info);
  display: table-row;
  font-size: 16px;
  line-height: 20px;
  margin-right: 5px;
  white-space: pre-wrap;
}
.play-log-container .play-log-content-container .message .warn[data-v-5f027579] {
  color: var(--log-color-warning);
  display: table-row;
  font-size: 13px;
  line-height: 18px;
  margin-right: 5px;
  white-space: pre-wrap;
}
.play-log-container .play-log-content-container .message .error[data-v-5f027579] {
  color: var(--log-color-error);
  display: table-row;
  font-size: 15px;
  line-height: 19.5px;
  margin-right: 5px;
  white-space: pre-wrap;
}
.play-log-container .play-log-content-container .message .fatal[data-v-5f027579] {
  color: var(--log-color-fatal);
  display: table-row;
  font-size: 15px;
  line-height: 19.5px;
  margin-right: 5px;
  white-space: pre-wrap;
}
.play-log-container .play-log-content-container .message .link[data-v-5f027579] {
  color: var(--log-color-link);
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
  margin-right: 5px;
}
.play-log-container .play-log-content-container .backtrace[data-v-5f027579] {
  color: var(--log-color-error);
  display: table-row;
  font-size: 14px;
  line-height: 19.5px;
  margin-right: 5px;
}
.play-log-container .play-log-content-container .backtrace .trace-link[data-v-5f027579] {
  color: var(--button-blue);
  cursor: pointer;
}
.play-log-container .play-log-content-container .backtrace .trace-link[data-v-5f027579]:hover {
  text-decoration: underline;
}
.play-log-container .play-log-content-container .icon[data-v-5f027579] {
  color: var(--button-blue);
  margin-right: 4px;
  cursor: pointer;
}