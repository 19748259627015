.rflex[data-v-3ac8ed05] {
  display: flex;
  overflow: hidden;
}
.rflex .area[data-v-3ac8ed05] {
  overflow: auto;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}
.rflex .stick[data-v-3ac8ed05] {
  background-color: var(--secondary-background-color);
  flex-shrink: 0;
  transition: background-color 0.3s linear 0.1s;
}
.rflex.vertical[data-v-3ac8ed05] {
  height: 100%;
  flex-direction: column;
}
.rflex.vertical.fill[data-v-3ac8ed05] {
  width: 100%;
}
.rflex.vertical.reverse[data-v-3ac8ed05] {
  flex-direction: column-reverse;
}
.rflex.vertical .area[data-v-3ac8ed05] {
  min-height: 0;
}
.rflex.vertical > .stick[data-v-3ac8ed05] {
  width: 100%;
  cursor: ns-resize;
}
.rflex.horizontal[data-v-3ac8ed05] {
  width: 100%;
  flex-direction: row;
}
.rflex.horizontal.fill[data-v-3ac8ed05] {
  height: 100%;
}
.rflex.horizontal.reverse[data-v-3ac8ed05] {
  flex-direction: row-reverse;
}
.rflex.horizontal .area[data-v-3ac8ed05] {
  min-width: 0;
}
.rflex.horizontal > .stick[data-v-3ac8ed05] {
  height: 100%;
  cursor: ew-resize;
}