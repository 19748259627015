.element-collection-info-container[data-v-2ab2bc86] {
  display: flex;
  flex-direction: column;
}
.element-collection-info-container .title[data-v-2ab2bc86] {
  width: 100%;
  text-align: center;
  font-size: 0.9em;
  position: relative;
}
.element-collection-info-container .title .toggle-show[data-v-2ab2bc86] {
  right: 2px;
  cursor: pointer;
  position: absolute;
  font-size: 0.8em;
  color: var(--font-color-secondary);
}
.element-collection-info-container .title .toggle-show[data-v-2ab2bc86]:hover {
  text-decoration: underline;
  color: var(--font-color);
}
.element-collection-info-container .element[data-v-2ab2bc86] {
  display: flex;
  flex-direction: row;
  background: var(--ternary-background-color);
  border-radius: 5px;
  padding-inline: 5px;
  padding-block: 1px;
  margin-block: 2px;
  width: calc(100% - 10px);
  cursor: pointer;
}
.element-collection-info-container .element[data-v-2ab2bc86]:hover {
  filter: brightness(1.3);
}
.element-collection-info-container .element .index[data-v-2ab2bc86] {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  margin-right: 8px;
  margin-left: 3px;
}
.element-collection-info-container .element .attributes[data-v-2ab2bc86] {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}