.novnc-container[data-v-a90adec3] {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.novnc-container .screen-overlay-container[data-v-a90adec3] {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: none;
  overflow: hidden;
}
.novnc-container .screen-overlay-container .canvas-layer[data-v-a90adec3] {
  width: 0;
  height: 0;
  margin: auto;
  position: relative;
}
.novnc-container .screen-overlay-container .disconnected-container[data-v-a90adec3] {
  width: 100%;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  justify-content: center;
  align-items: center;
}