.splitter[data-v-79999fcf] {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 1px;
  background-color: var(--splitter-background-color);
}
.category[data-v-79999fcf] {
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--font-color-secondary-dark-mode);
}
.item[data-v-79999fcf] {
  font-size: 16px;
  line-height: 25px;
  color: var(--font-color);
  border-radius: 10px;
  padding-left: 10px;
  cursor: pointer;
}
.item.selected[data-v-79999fcf], .item[data-v-79999fcf]:hover {
  background-color: var(--ternary-background-color);
}