.usage[data-v-9ebf1aff] {
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  align-items: center;
  color: var(--font-color-secondary);
  font-size: 12px;
  font-family: monospace;
}
.usage[data-v-9ebf1aff]:hover, .usage[data-v-9ebf1aff]:focus {
  color: var(--font-color);
  background-color: var(--sidebar-selected);
}
.usage .icon[data-v-9ebf1aff] {
  text-align: center;
  color: var(--button-red);
  display: flex;
  width: 12px;
  flex-shrink: 0;
  font-size: 8px;
}
.usage .name[data-v-9ebf1aff] {
  text-align: left;
  display: flex;
  white-space: pre;
  margin-right: 30px;
  overflow: hidden;
  max-width: 75%;
  min-width: 25%;
  flex-shrink: 0;
}
.usage .code[data-v-9ebf1aff] {
  justify-content: flex-end;
  display: flex;
  white-space: pre;
  min-width: 25%;
  color: var(--font-color);
  overflow: hidden;
  font-size: 0.8em;
  width: 100%;
  padding-right: 4px;
}