.result[data-v-d0efe4ac] {
  height: 20px;
  display: flex;
  padding: 2px 4px;
  font-size: 0.9em;
  cursor: pointer;
  align-items: center;
  border-left: 1px solid transparent;
  flex-shrink: 0;
}
.result[data-v-d0efe4ac]:hover {
  background-color: var(--ternary-background-color);
}
.result.active[data-v-d0efe4ac] {
  border-left: 1px solid var(--button-blue);
  background-color: var(--sidebar-selected);
}
.result div.icon[data-v-d0efe4ac] {
  margin-right: 8px;
  padding-left: 3px;
  font-size: 0.85em;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-width: 15px;
}
.result div.name[data-v-d0efe4ac] {
  color: var(--font-color);
  margin-right: 5px;
  white-space: nowrap;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}
.result div.name span.highlight[data-v-d0efe4ac] {
  background: var(--sidebar-selected-contrast);
}
.result div.info[data-v-d0efe4ac] {
  font-size: 0.9em;
  font-family: monospace;
  color: var(--font-color-secondary);
  white-space: nowrap;
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
}