.diff-container[data-v-68207344] {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.diff-container .labels-container[data-v-68207344] {
  display: flex;
  width: 100%;
  flex-direction: row;
  font-family: monospace;
  font-size: 13px;
  height: 17px;
  border-bottom: 1px solid var(--secondary-background-color);
  align-items: center;
}
.diff-container .labels-container .snippet-label[data-v-68207344] {
  padding-left: 5px;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.diff-container .labels-container .gap[data-v-68207344] {
  width: 50px;
}
.diff-container .labels-container .other-label[data-v-68207344] {
  width: 50%;
  text-align: right;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.diff-container .diff[data-v-68207344] {
  height: calc(100% - 52px);
  width: 100%;
}
.diff-container .buttons-container[data-v-68207344] {
  display: flex;
  flex-direction: row;
  height: 33px;
  border-top: 1px solid var(--secondary-background-color);
  align-items: center;
}
.diff-container .buttons-container .left[data-v-68207344] {
  width: 50%;
}
.diff-container .buttons-container .gap[data-v-68207344] {
  width: 50px;
}
.diff-container .buttons-container .right[data-v-68207344] {
  width: 50%;
  display: flex;
  flex-direction: row-reverse;
}