.container[data-v-02d13b4d] {
  width: 100%;
  display: flex;
  padding-top: 10px;
  flex-direction: column;
}
.container .usages-target[data-v-02d13b4d] {
  align-items: baseline;
}
.container .usages-target .label-name[data-v-02d13b4d] {
  color: var(--font-color-secondary);
  font-size: 0.8em;
  margin-right: 5px;
}
.container .usages-target .target-icon[data-v-02d13b4d] {
  font-size: 0.9em;
  margin-inline: 5px;
}