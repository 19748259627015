.container[data-v-2878c0ec] {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.container .actions[data-v-2878c0ec] {
  width: 28px;
  font-size: 0.8em;
  border-right: 2px solid var(--secondary-background-color);
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.container .content-wrapper[data-v-2878c0ec] {
  width: calc(100% - 29px);
  display: flex;
  flex-direction: column;
}
.container .content-wrapper .logs-toolbar[data-v-2878c0ec] {
  height: 28px;
  flex-shrink: 0;
  width: 100%;
  border-bottom: 2px solid var(--secondary-background-color);
  align-items: center;
  display: flex;
}
.container .content-wrapper .logs-toolbar .info-toolbar[data-v-2878c0ec],
.container .content-wrapper .logs-toolbar .filter-toolbar[data-v-2878c0ec],
.container .content-wrapper .logs-toolbar .reject-toolbar[data-v-2878c0ec],
.container .content-wrapper .logs-toolbar .time-toolbar[data-v-2878c0ec] {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 5px;
  width: 100%;
}
.container .content-wrapper .logs-toolbar .info-toolbar .load-progress[data-v-2878c0ec] {
  margin-inline: 5px;
  display: flex;
  white-space: nowrap;
}
.container .content-wrapper .logs-toolbar .info-toolbar .load-bar[data-v-2878c0ec]:first-child {
  border-left: 1px solid var(--button-grey);
}
.container .content-wrapper .logs-toolbar .info-toolbar .load-bar[data-v-2878c0ec] {
  border-right: 1px solid var(--button-grey);
  border-top: 1px solid var(--button-grey);
  border-bottom: 1px solid var(--button-grey);
  width: 100%;
  height: 10px;
  display: flex;
  flex-direction: row;
}
.container .content-wrapper .logs-toolbar .info-toolbar .load-bar .load-bar-chunk[data-v-2878c0ec] {
  border: 1px solid var(--button-grey);
}
.container .content-wrapper .content[data-v-2878c0ec] {
  width: 100%;
  height: calc(100% - 31px);
  overflow: hidden;
}