.attribute-item[data-v-6bba21ef] {
  font-size: 0.85em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 2px;
  padding-block: 1px;
  margin-block: 1px;
  background: var(--ternary-background-color);
  cursor: pointer;
  width: calc(100% - 4px);
}
.attribute-item[data-v-6bba21ef]:hover {
  filter: brightness(1.3);
}
.attribute-item .icon[data-v-6bba21ef] {
  margin-right: 4px;
  font-size: 0.6em;
  min-width: 10px;
}
.attribute-item .label[data-v-6bba21ef] {
  color: var(--font-color-secondary);
  margin-right: 3px;
  white-space: nowrap;
}
.attribute-item .count[data-v-6bba21ef] {
  font-size: 0.7em;
  margin-right: 3px;
  flex-shrink: 0;
}
.attribute-item .value[data-v-6bba21ef] {
  font-family: monospace;
  color: var(--font-color);
  width: 100%;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attribute-item .value span.highlight[data-v-6bba21ef] {
  background: var(--sidebar-selected-contrast);
}