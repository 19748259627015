.error-info[data-v-6144c242] {
  pointer-events: all;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: var(--ternary-background-color);
  color: var(--font-color);
  border: 1px solid var(--button-red);
  padding: 5px 5px 5px 20px;
  border-radius: 5px;
  font-size: 14px;
  min-width: 120px;
  z-index: 1;
}
.error-info.top[data-v-6144c242], .error-info.bottom[data-v-6144c242] {
  left: 0;
}
.error-info.top.center[data-v-6144c242]:before, .error-info.bottom.center[data-v-6144c242]:before {
  left: 50%;
}
.error-info.top.center[data-v-6144c242]:after, .error-info.bottom.center[data-v-6144c242]:after {
  left: 50%;
}
.error-info.top[data-v-6144c242]:not(.center):before, .error-info.bottom[data-v-6144c242]:not(.center):before {
  left: 10px;
}
.error-info.top[data-v-6144c242]:not(.center):after, .error-info.bottom[data-v-6144c242]:not(.center):after {
  left: 10px;
}
.error-info.right[data-v-6144c242], .error-info.left[data-v-6144c242] {
  top: 0;
}
.error-info.right.center[data-v-6144c242]:before, .error-info.left.center[data-v-6144c242]:before {
  top: 50%;
}
.error-info.right.center[data-v-6144c242]:after, .error-info.left.center[data-v-6144c242]:after {
  top: 50%;
}
.error-info.right[data-v-6144c242]:not(.center):before, .error-info.left[data-v-6144c242]:not(.center):before {
  top: 7px;
}
.error-info.right[data-v-6144c242]:not(.center):after, .error-info.left[data-v-6144c242]:not(.center):after {
  top: 7px;
}
.error-info[data-v-6144c242]:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
}
.error-info[data-v-6144c242]:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
}
.error-info.bottom[data-v-6144c242] {
  margin-top: 0;
  top: calc(100% + 10px);
}
.error-info.bottom[data-v-6144c242]:before {
  bottom: 100%;
  border-bottom-color: var(--button-red);
}
.error-info.bottom[data-v-6144c242]:after {
  bottom: calc(100% - 1px);
  border-bottom-color: var(--ternary-background-color);
}
.error-info.top[data-v-6144c242] {
  margin-bottom: 0;
  bottom: calc(100% + 10px);
}
.error-info.top[data-v-6144c242]:before {
  top: 100%;
  border-top-color: var(--button-red);
}
.error-info.top[data-v-6144c242]:after {
  top: calc(100% - 1px);
  border-top-color: var(--ternary-background-color);
}
.error-info.right[data-v-6144c242] {
  margin-top: 0;
  left: calc(100% + 10px);
}
.error-info.right[data-v-6144c242]:before {
  right: 100%;
  border-right-color: var(--button-red);
}
.error-info.right[data-v-6144c242]:after {
  right: calc(100% - 1px);
  border-right-color: var(--ternary-background-color);
}
.error-info.left[data-v-6144c242] {
  margin-top: 0;
  right: calc(100% + 10px);
}
.error-info.left[data-v-6144c242]:before {
  left: 100%;
  border-left-color: var(--button-red);
}
.error-info.left[data-v-6144c242]:after {
  left: calc(100% - 1px);
  border-left-color: var(--ternary-background-color);
}