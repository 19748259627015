.project-row[data-v-5c4ddacb] {
  cursor: pointer;
}
.project-row .action[data-v-5c4ddacb] {
  cursor: pointer;
  width: 10px;
  display: flex;
  justify-content: center;
}
.project-row .action.delete[data-v-5c4ddacb] {
  color: var(--button-red);
}