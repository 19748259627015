.container[data-v-7eac696d] {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.container .label[data-v-7eac696d] {
  color: var(--font-color-secondary);
  font-size: 0.9em;
  margin-left: 5px;
  padding-block: 5px;
}
.container .overlay[data-v-7eac696d] {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  z-index: 1;
}
.container .groups-scenarios-container[data-v-7eac696d] {
  position: relative;
  height: 100%;
}
.container .groups-scenarios-container .dnd-drop-areas-container[data-v-7eac696d] {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.container .groups-scenarios-container .dnd-drop-areas-container .dnd-over-drop-area[data-v-7eac696d] {
  outline: none;
  border: 2px solid var(--button-blue);
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  pointer-events: none;
}