.scenario-scenario-label,
.scenario-snippet-label {
  color: var(--sb-label);
  font-size: 8px;
  width: calc(var(--sb-node-width) - 3px);
  height: var(--sb-node-height);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  white-space: normal;
}