.browser-row[data-v-63fcd064] {
  cursor: pointer;
}
.browser-row td.green[data-v-63fcd064] {
  color: var(--button-green);
}
.browser-row td.red[data-v-63fcd064] {
  color: var(--button-red);
}
.job-status.green[data-v-63fcd064] {
  color: var(--button-green);
}
.job-status.red[data-v-63fcd064] {
  color: var(--button-red);
}