.code-search-container[data-v-d03964cc] {
  min-width: 100px;
  max-width: 90vw;
  min-height: 200px;
  max-height: 90vh;
  resize: both;
  overflow: auto;
}
.results-container[data-v-d03964cc] {
  display: flex;
  font-size: 13px;
  padding: 2px 4px;
  font-family: monospace;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 39px);
}
.results-container .result[data-v-d03964cc] {
  height: 20px;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  align-items: center;
  border-left: 1px solid transparent;
}
.results-container .result[data-v-d03964cc]:hover {
  background-color: var(--ternary-background-color);
}
.results-container .result.active[data-v-d03964cc] {
  box-sizing: border-box;
  border-left: 1px solid var(--button-blue);
  background-color: var(--sidebar-selected);
}
.results-container .result .code[data-v-d03964cc] {
  text-align: left;
  display: flex;
  white-space: pre;
  margin-right: 10px;
  overflow: hidden;
  max-width: 75%;
  min-width: 25%;
}
.results-container .result .name[data-v-d03964cc] {
  justify-content: flex-end;
  font-size: 10px;
  display: flex;
  white-space: nowrap;
  max-width: 75%;
  min-width: 25%;
  margin-left: auto;
  overflow: hidden;
  color: var(--font-color-secondary);
}
.results-container .no-results-container[data-v-d03964cc] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.7em;
}