.inspector-element-modal {
  position: fixed;
  display: inline-block;
  padding: 0.25em 0;
  margin: 0.3em;
  font-family: inherit;
  font-size: 13px;
  list-style-type: none;
  background-color: var(--secondary-background-color);
  border: 1px solid var(--border-contrast-color);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.inspector-element-modal-item {
  position: relative;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  padding: 0.2em 0.75em 0.2em 2em;
  color: var(--font-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--secondary-background-color);
}
.inspector-element-modal-item:hover {
  filter: brightness(1.1);
  background-color: var(--primary-background-color);
}
.inspector-element-modal-item-icon {
  position: absolute;
  top: 0.3em;
  left: 0.5em;
}
.inspector-element-modal-item-key {
  color: var(--font-color-secondary);
  float: right;
  font-size: 11px;
  text-shadow: none;
}