.vue-button[data-v-dbcd5334] {
  white-space: nowrap;
  border-radius: 5px;
  height: fit-content;
  color: var(--font-color);
  padding: 5px 9px 5px;
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  overflow: hidden;
  align-items: center;
}
.vue-button i[data-v-dbcd5334] {
  margin-left: 0.3em;
}
@keyframes buttonLoadSlideIn-dbcd5334 {
from {
    margin-left: 0;
    padding-inline: 0;
    width: 0;
}
to {
    margin-left: 5px;
    padding-inline: 2px;
    width: 100%;
}
}
.vue-button .loading-container[data-v-dbcd5334] {
  min-width: 0;
  margin-left: 0;
  padding-inline: 0;
  width: 0;
  animation: buttonLoadSlideIn-dbcd5334 0.5s ease-in 0.5s forwards;
  overflow: hidden;
}
.vue-button[data-v-dbcd5334]:not(:focus) {
  border: 1px solid var(--border-color-dark);
}
.vue-button[data-v-dbcd5334]:not(.disabled):hover {
  filter: brightness(1.2);
}
.vue-button.disabled[data-v-dbcd5334] {
  cursor: not-allowed;
}
.vue-button.default[data-v-dbcd5334] {
  background-color: var(--secondary-background-color);
}
.vue-button.grey[data-v-dbcd5334] {
  background-color: var(--button-grey);
}
.vue-button.red[data-v-dbcd5334] {
  background-color: var(--button-red);
}
.vue-button.green[data-v-dbcd5334] {
  background-color: var(--button-green);
}
.vue-button.blue[data-v-dbcd5334] {
  background-color: var(--button-blue);
}
.vue-button.white[data-v-dbcd5334] {
  background-color: var(--button-white);
}