.splitter-container[data-v-28803b9b] {
  width: 100%;
  height: 100%;
  position: relative;
}
.dnd-drop-areas-container[data-v-28803b9b] {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.dnd-drop-areas-container .dnd-over-drop-area[data-v-28803b9b] {
  outline: none;
  border: 2px solid var(--button-blue);
}
.dnd-drop-areas-container .dnd-over-drop-area.main[data-v-28803b9b] {
  height: calc(100% - 4px);
  width: calc(100% - 4px);
}