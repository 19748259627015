.tab-manager-container[data-v-aad288bb] {
  width: 100%;
  height: calc(100% - 2px);
  background-color: var(--secondary-background-color);
  display: grid;
}
.tab-manager-container .tabs[data-v-aad288bb] {
  grid-area: tabs;
  background-color: var(--secondary-background-color);
  display: flex;
  border: 1px solid var(--secondary-background-color);
  overflow-x: auto;
}
.tab-manager-container .tabs.top[data-v-aad288bb], .tab-manager-container .tabs.bottom[data-v-aad288bb] {
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  flex-direction: row;
}
.tab-manager-container .tabs.left[data-v-aad288bb], .tab-manager-container .tabs.right[data-v-aad288bb] {
  height: 100%;
  width: fit-content;
  flex-direction: column;
}
.tab-manager-container .content[data-v-aad288bb] {
  grid-area: content;
  position: relative;
  background-color: var(--primary-background-color);
  width: 100%;
  height: 100%;
  overflow: auto;
}
.tab-manager-container .content .dnd-drop-areas-container[data-v-aad288bb] {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 75% 25%;
  grid-auto-columns: 70% 30%;
  grid-template-areas: "main right" "down right";
  z-index: 9;
}
.tab-manager-container .content .dnd-drop-areas-container.scenario-builder-tab_manager[data-v-aad288bb] {
  pointer-events: none;
}
.tab-manager-container .content .dnd-drop-areas-container .dnd-over-drop-area[data-v-aad288bb] {
  outline: none;
  border: 2px solid var(--button-blue);
}
.tab-manager-container .content .dnd-drop-areas-container .dnd-over-drop-area.main[data-v-aad288bb] {
  grid-area: main;
  border-right-width: 0;
  border-bottom-width: 0;
}
.tab-manager-container .content .dnd-drop-areas-container .dnd-over-drop-area.main.single[data-v-aad288bb] {
  border-right-width: 2px;
  border-bottom-width: 2px;
}
.tab-manager-container .content .dnd-drop-areas-container .dnd-over-drop-area.right[data-v-aad288bb] {
  grid-area: right;
}
.tab-manager-container .content .dnd-drop-areas-container .dnd-over-drop-area.down[data-v-aad288bb] {
  border-right-width: 0;
  grid-area: down;
}