.search-container[data-v-1f860e7a] {
  width: 100%;
  height: 25px;
  border-bottom: 1px solid var(--secondary-background-color);
  background-color: var(--ternary-background-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search-container .match-counter[data-v-1f860e7a] {
  white-space: nowrap;
  min-width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
}