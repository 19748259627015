div.color-picker[data-v-09863748] {
  align-items: center;
}
div.color-picker.invalid span[data-v-09863748] {
  color: var(--button-red);
}
div.color-picker span[data-v-09863748] {
  margin-top: 4px;
  margin-left: 3px;
  padding: 3px;
  cursor: pointer;
}
div.color-picker .color-picker-wrapper[data-v-09863748] {
  width: 1.5em;
  height: 1.5em;
  position: relative;
  cursor: pointer;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.color-picker .color-picker-wrapper.square[data-v-09863748] {
  border-radius: 5px;
}
div.color-picker .color-picker-wrapper.circle[data-v-09863748] {
  border-radius: 50%;
}
div.color-picker .color-picker-icon[data-v-09863748] {
  margin: 2px;
}
div.color-picker .color-picker-dropdown[data-v-09863748] {
  cursor: default;
  position: absolute;
  top: 1.8em;
  left: 0;
  background: var(--ternary-background-color);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--border-color-dark);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
}
div.color-picker .color-picker-grid[data-v-09863748] {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 3px;
}
div.color-picker .color-picker-color[data-v-09863748] {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid var(--border-color-dark);
}
div.color-picker .color-picker-color[data-v-09863748]:hover, div.color-picker .color-picker-color[data-v-09863748]:focus {
  border-color: var(--border-color-light);
}
div.color-picker .color-picker-check[data-v-09863748] {
  width: 18px;
  height: 18px;
  margin: 3px;
}
div.color-picker .color-picker-remove[data-v-09863748] {
  font-family: sans-serif;
  font-size: 12px;
  color: var(--font-color);
  margin-top: 3px;
  padding: 5px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  background: var(--primary-background-color);
}
div.color-picker .color-picker-remove[data-v-09863748]:hover, div.color-picker .color-picker-remove[data-v-09863748]:focus {
  filter: brightness(1.2);
}