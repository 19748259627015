.image-capture-container[data-v-19457814] {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.image-capture-container .images-controls-container[data-v-19457814] {
  padding: 10px;
  height: 40px;
}
.image-capture-container .images-controls-container .images-controls[data-v-19457814] {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
}
.image-capture-container .images-controls-container .images-controls .image-folder-path[data-v-19457814] {
  color: var(--font-color-secondary);
  margin-inline: 4px;
  white-space: nowrap;
  font-size: 0.9em;
}
.image-capture-container .images-controls-container .images-controls .image-extension[data-v-19457814] {
  color: var(--font-color-secondary);
  margin-inline: 4px;
}
.image-capture-container .images-upload-notification[data-v-19457814] {
  cursor: pointer;
  font-size: 1.4em;
  padding-top: 5px;
  padding-left: 10px;
}
.image-capture-container .images-screenshot[data-v-19457814] {
  height: calc(100% - 40px);
  width: 100%;
  text-align: center;
}