.search-record-container[data-v-14afaaa0] {
  min-width: 100px;
  max-width: 90vw;
  min-height: 200px;
  max-height: 90vh;
  resize: both;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.search-record-container .search-scope-container[data-v-14afaaa0] {
  display: flex;
  flex-direction: row;
  overflow: auto;
  flex-shrink: 0;
  height: 40px;
}
.search-record-container .search-input-container[data-v-14afaaa0] {
  position: relative;
  flex-shrink: 0;
  height: 30px;
}
.search-record-container .search-input-container .search-input-overlay[data-v-14afaaa0] {
  position: absolute;
  right: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 5;
  display: flex;
  pointer-events: none;
}
.search-record-container .search-result-container[data-v-14afaaa0] {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-shrink: 1;
  height: calc(100% - 30px - 40px);
}
.search-record-container .search-result-container .load-more[data-v-14afaaa0] {
  margin-top: 3px;
  font-size: 0.85em;
  padding: 3px;
  cursor: pointer;
  color: var(--font-color-secondary);
}
.search-record-container .search-result-container .load-more[data-v-14afaaa0]:hover {
  background-color: var(--ternary-background-color);
  color: var(--font-color);
}
.search-record-container .search-result-container .load-more.active[data-v-14afaaa0] {
  color: var(--font-color);
  border-left: 1px solid var(--button-blue);
  background-color: var(--sidebar-selected);
}