.container[data-v-04d85089] {
  overflow: auto;
  resize: both;
  max-width: 90vw;
  max-height: 90vh;
}
.container .no-results[data-v-04d85089] {
  padding: 5px;
  font-family: monospace;
  font-size: 12px;
}
.divider[data-v-04d85089] {
  border-bottom: solid 1px var(--font-color-secondary);
  border-top: solid 1px var(--font-color-secondary);
  text-align: center;
  cursor: pointer;
  color: var(--font-color-secondary);
  font-size: 12px;
  font-family: monospace;
}