span.toggle-icon-container[data-v-b4e4d2e3] {
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  aspect-ratio: 1/1;
  margin-inline: 0.1em;
  font-size: 1.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
}
span.toggle-icon-container span.toggle-icon[data-v-b4e4d2e3] {
  margin: 0.25em;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  border-radius: 50%;
}
span.toggle-icon-container span.toggle-icon[data-v-b4e4d2e3]:hover, span.toggle-icon-container span.toggle-icon[data-v-b4e4d2e3]:focus {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.55);
  background-color: var(--primary-background-color);
  filter: brightness(1.3);
}
span.toggle-icon-container span.toggle-icon i[data-v-b4e4d2e3] {
  margin: 0.25em;
}