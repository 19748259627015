label.custom-field[data-v-9c684974] {
  position: relative;
  font-size: 14px;
  display: flex;
  width: 100%;
}
label.custom-field textarea[data-v-9c684974] {
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--ternary-background-color);
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  width: 100%;
  color: var(--font-color);
  outline: none;
  line-height: normal;
  font-size: 14px;
  transition: border-color 0.3s ease;
  max-height: 500px;
  resize: none;
}
label.custom-field .placeholder[data-v-9c684974] {
  background-color: var(--ternary-background-color);
  position: absolute;
  left: 12px;
  width: calc(100% - 24px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 20px;
  line-height: 100%;
  transform: translateY(-50%);
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
}
label.custom-field textarea + .placeholder[data-v-9c684974] {
  left: 8px;
  padding: 0 5px;
}
label.custom-field textarea:not(:focus).dirty[data-v-9c684974] {
  transition-delay: 0.1s;
}
label.custom-field textarea[data-v-9c684974]:not(:placeholder-shown),
label.custom-field textarea[data-v-9c684974]:focus {
  transition-delay: 0.1s;
}
label.custom-field textarea.dirty + .placeholder[data-v-9c684974],
label.custom-field textarea:not(:placeholder-shown) + .placeholder[data-v-9c684974],
label.custom-field textarea:focus + .placeholder[data-v-9c684974] {
  top: 0;
  font-size: 10px;
  width: auto;
}
label.custom-field textarea:disabled.dirty[data-v-9c684974],
label.custom-field textarea[data-v-9c684974]:disabled:not(:placeholder-shown),
label.custom-field textarea[data-v-9c684974]:disabled:focus {
  border-color: var(--font-color-secondary);
  transition-delay: 0.1s;
}
label.custom-field textarea:disabled.dirty + .placeholder[data-v-9c684974],
label.custom-field textarea:disabled:not(:placeholder-shown) + .placeholder[data-v-9c684974],
label.custom-field textarea:disabled:focus + .placeholder[data-v-9c684974] {
  top: 0;
  font-size: 10px;
  width: auto;
}
label:not(.invalid).custom-field textarea[data-v-9c684974] {
  border-color: var(--secondary-background-color);
}
label:not(.invalid).custom-field .placeholder[data-v-9c684974] {
  color: var(--font-color-secondary);
}
label:not(.invalid).custom-field textarea:not(:focus).dirty[data-v-9c684974] {
  border-color: var(--secondary-background-color);
}
label:not(.invalid).custom-field textarea[data-v-9c684974]:not(:placeholder-shown),
label:not(.invalid).custom-field textarea[data-v-9c684974]:focus {
  border-color: var(--focus-color);
}
label:not(.invalid).custom-field textarea.dirty + .placeholder[data-v-9c684974],
label:not(.invalid).custom-field textarea:not(:placeholder-shown) + .placeholder[data-v-9c684974],
label:not(.invalid).custom-field textarea:focus + .placeholder[data-v-9c684974] {
  color: var(--focus-color);
}
label:not(.invalid).custom-field textarea:disabled.dirty + .placeholder[data-v-9c684974],
label:not(.invalid).custom-field textarea:disabled:not(:placeholder-shown) + .placeholder[data-v-9c684974],
label:not(.invalid).custom-field textarea:disabled:focus + .placeholder[data-v-9c684974] {
  color: var(--font-color-secondary);
}
label.invalid.custom-field input[data-v-9c684974] {
  border-color: var(--button-red);
}
label.invalid.custom-field .placeholder[data-v-9c684974] {
  color: var(--button-red);
}
label.invalid.custom-field textarea:disabled.dirty + .placeholder[data-v-9c684974],
label.invalid.custom-field textarea:disabled:not(:placeholder-shown) + .placeholder[data-v-9c684974],
label.invalid.custom-field textarea:disabled:focus + .placeholder[data-v-9c684974] {
  color: var(--button-red);
}