.container[data-v-d39ef1f0] {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: end;
  flex-direction: column;
}
.container .info-grid[data-v-d39ef1f0] {
  display: grid;
  width: 100%;
  justify-content: center;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  font-size: 0.8em;
}
.container .label[data-v-d39ef1f0] {
  align-self: center;
  display: flex;
  text-align: right;
}
.container .value[data-v-d39ef1f0] {
  text-align: left;
  display: flex;
  align-items: center;
}