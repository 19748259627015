.jobberman-input-request-message {
  display: block;
  width: 100%;
  height: 100%;
}
.jobberman-input-request-message strong {
  padding-inline: 0.2em;
  white-space: nowrap;
}
.jobberman-input-request-message pre {
  margin-inline: 0.2em;
  margin-block: 0;
  display: inline-block;
  padding: 0.3em;
  background: var(--ternary-background-color);
  border-radius: 5px;
  user-select: none;
}