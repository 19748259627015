.diff-container[data-v-b3b154f1] {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.diff-container .labels-container[data-v-b3b154f1] {
  display: flex;
  width: 100%;
  flex-direction: row;
  font-family: monospace;
  font-size: 13px;
  height: 17px;
  border-bottom: 1px solid var(--secondary-background-color);
  align-items: center;
}
.diff-container .labels-container .label[data-v-b3b154f1] {
  width: 33%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.diff-container .labels-container .snippet-label[data-v-b3b154f1] {
  padding-left: 5px;
}
.diff-container .labels-container .gap[data-v-b3b154f1] {
  width: 50px;
}
.diff-container .labels-container .result-label[data-v-b3b154f1] {
  text-align: center;
}
.diff-container .labels-container .remote-label[data-v-b3b154f1] {
  text-align: right;
  padding-right: 10px;
}
.diff-container .diff[data-v-b3b154f1] {
  height: calc(100% - 52px);
  width: 100%;
}
.diff-container .buttons-container[data-v-b3b154f1] {
  display: flex;
  flex-direction: row;
  height: 33px;
  border-top: 1px solid var(--secondary-background-color);
  align-items: center;
}
.diff-container .buttons-container .left[data-v-b3b154f1] {
  width: 33%;
  padding-left: 15px;
}
.diff-container .buttons-container .gap[data-v-b3b154f1] {
  width: 50px;
}
.diff-container .buttons-container .result[data-v-b3b154f1] {
  width: 33%;
  justify-content: center;
  display: flex;
}
.diff-container .buttons-container .right[data-v-b3b154f1] {
  width: 33%;
  padding-right: 15px;
  display: flex;
  flex-direction: row-reverse;
}