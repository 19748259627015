.cm-searching::selection {
  background-color: var(--button-blue) !important;
  color: pink !important;
}
.codemirror-syntax-error {
  text-decoration: underline;
  text-decoration-color: var(--button-red);
  text-decoration-style: dotted;
  text-decoration-thickness: 3px;
}
.codemirror-offense {
  text-decoration: underline;
  text-decoration-color: gray;
  text-decoration-style: dotted;
  text-decoration-thickness: 3px;
}
.offense_tooltip {
  z-index: 999;
  position: absolute;
  display: inline-block;
  min-width: 13em;
  /*max-width: 26em;*/
  margin: 8px;
  padding: 8px;
  font-family: inherit;
  font-size: 12px;
  list-style-type: none;
  background-color: var(--secondary-background-color);
  border: 1px solid var(--border-contrast-color);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.CodeMirror-hints {
  background-color: var(--primary-background-color) !important;
}
.merged-code-background {
  background: var(--merged-code-background-color);
  border-radius: 2px;
  animation: merged-code-background 1s 1;
  -webkit-animation: merged-code-background 1s 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  -webkit-animation-delay: 1s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
}
@keyframes merged-code-background {
from {
    background: var(--merged-code-background-color);
}
to {
    background: transparent;
}
}
@-webkit-keyframes merged-code-background {
from {
    background: var(--merged-code-background-color);
}
to {
    background: transparent;
}
}
.jump-to-highlight-background {
  background: var(--jump-to-highlight-background-color);
  border-radius: 2px;
  animation: jump-to-highlight-background 1s 1;
  -webkit-animation: jump-to-highlight-background 1s 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  -webkit-animation-delay: 1s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
}
@keyframes jump-to-highlight-background {
from {
    background: var(--jump-to-highlight-background-color);
}
to {
    background: transparent;
}
}
@-webkit-keyframes jump-to-highlight-background {
from {
    background: var(--jump-to-highlight-background-color);
}
to {
    background: transparent;
}
}
.offense-gutter {
  color: yellow;
  font-size: 0.5em;
  cursor: pointer;
  padding-left: 0;
  margin-top: 0.8em;
  opacity: 0.3;
}
.offense-context-menu {
  font-size: 0.75em;
}
.offense-context-menu .context-menu-list {
  font-size: 0.9em;
}
.offense-context-menu .context-menu-key {
  width: auto;
  padding-left: 5px;
  color: var(--button-green);
}