.debug-container[data-v-b904b25c] {
  width: calc(100% - 33px);
  height: 100%;
  min-height: 50px;
  padding-right: 33px;
  border-top: 1px solid var(--font-color-secondary);
}
.debug-action[data-v-b904b25c] {
  position: relative;
  float: right;
  margin-top: -22px;
  padding-right: 300px;
  margin-right: -33px;
}
.debug-action.second[data-v-b904b25c] {
  margin-top: 0;
}
.debug-action .abs-wrapper[data-v-b904b25c] {
  position: absolute;
  width: 300px;
  text-align: right;
  z-index: 11;
  height: 0;
  margin-top: -5px;
}
.debug-action .abs-wrapper .image-capture-and-frame-sel[data-v-b904b25c] {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.debug-action .abs-wrapper .image-capture-and-frame-sel .frame-sel-container[data-v-b904b25c] {
  padding: 1px 6px 1px 6px;
  max-width: 300px;
  float: right;
  width: 100px;
}
.debug-action .abs-wrapper .image-capture-and-frame-sel .frame-sel-container select[data-v-b904b25c] {
  height: 20px;
  font-size: 10px;
  max-width: 288px;
  background: var(--primary-background-color);
}
.debug-action .abs-wrapper .submit-container[data-v-b904b25c] {
  display: flex;
  float: right;
  align-items: center;
  justify-content: right;
  cursor: pointer;
}