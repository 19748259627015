.snippet-editor-container[data-v-8c2d3b28] {
  position: relative;
  width: 100%;
  height: 100%;
}
.snippet-editor-container .overlay[data-v-8c2d3b28] {
  position: absolute;
  right: 6px;
  top: 3px;
  z-index: 5;
}