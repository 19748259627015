.sections-content[data-v-d59b8a7a] {
  width: 100%;
  height: 100%;
  display: flex;
  min-width: 0;
}
.sections-content .section-header[data-v-d59b8a7a] {
  display: flex;
  flex-direction: row-reverse;
  height: 25px !important;
  font-size: 0.75em;
  border-bottom: 1px solid var(--secondary-background-color);
}
.sections-content .section-header .section-title[data-v-d59b8a7a] {
  margin-left: 4px;
  font-size: 1.1em;
}
.sections-content .section-body[data-v-d59b8a7a] {
  overflow: auto;
  width: 100%;
  height: calc(100% - 25px) !important;
}