.project-edit[data-v-d18e390e] {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.project-edit .header[data-v-d18e390e] {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.project-edit .body[data-v-d18e390e] {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}