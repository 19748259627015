.play-content-container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
.play-content-container.in-debug {
  height: 100%;
}
.play-node-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--font-color);
  position: relative;
}
.play-node-container.in-debug {
  height: 100%;
}
.play-node-container .row-container {
  display: flex;
  flex-direction: row;
  padding-left: 3px;
  height: 27px;
  align-items: center;
  justify-content: flex-start;
  background: var(--primary-background-color);
}
.play-node-container .row-container .click-item {
  cursor: pointer;
}
.play-node-container .row-container .click-item:hover {
  color: var(--font-color-hover);
  filter: brightness(1.2);
}
.play-node-container .row-container .expander {
  display: flex;
  justify-content: center;
  width: 20px;
  flex-shrink: 0;
  font-size: 0.65em;
  padding-bottom: 2px;
  color: var(--font-color-secondary);
}
.play-node-container .row-container .title-container {
  display: flex;
  flex-direction: row;
  padding-left: 2px;
  padding-right: 2px;
  width: 100%;
  flex-shrink: 1;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  overflow: hidden;
  container-type: inline-size;
  /* If the container is larger than 500px. show status notes */
}
.play-node-container .row-container .title-container .progress-container {
  width: 100%;
  min-width: 0;
  display: flex;
}
.play-node-container .row-container .title-container .progress-container .title {
  flex-shrink: 0;
  display: flex;
  font-size: 1.25em;
  white-space: pre;
  max-width: 95%;
  overflow: hidden;
}
.play-node-container .row-container .title-container .progress-container .title:hover {
  text-shadow: var(--text-shadow-hover);
}
.play-node-container .row-container .title-container .progress-container .status-container {
  flex-shrink: 2;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: none;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
}
.play-node-container .row-container .title-container .progress-container .status-container .status-note {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
}
.play-node-container .row-container .title-container .progress-container .status-screenshot {
  display: none;
  align-self: center;
  font-size: 1.05em;
}
@container (min-width: 500px) {
.play-node-container .row-container .title-container .progress-container .title {
    max-width: 70%;
}
.play-node-container .row-container .title-container .progress-container .status-container {
    display: flex;
    padding-right: 0;
}
.play-node-container .row-container .title-container .progress-container .status-screenshot {
    display: flex;
}
}
.play-node-container .row-container .title-container .title-item {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 0.8em;
  min-width: 0;
  /* If the container is larger than 600px. show datetime, and hide time */
}
.play-node-container .row-container .title-container .title-item.icon {
  flex-shrink: 0;
  font-size: 0.8em;
}
.play-node-container .row-container .title-container .title-item.start-date {
  flex-shrink: 0;
  display: none;
}
@container (min-width: 600px) {
.play-node-container .row-container .title-container .title-item.start-date {
    display: flex;
}
}
.play-node-container .row-container .title-container .title-item.start-time {
  flex-shrink: 0;
}
.play-node-container .row-container .title-container .title-item.duration {
  width: 50px;
  white-space: nowrap;
  flex-shrink: 0;
  text-align: right;
  min-width: 20px;
}
.play-node-container .row-container .title-container .title-item.flex-expander {
  width: 100%;
  flex-shrink: 999;
}
.play-node-container .row-container .action-container {
  flex-shrink: 0;
  padding-right: 2px;
  display: inline-flex;
  font-size: 0.7em;
  align-items: baseline;
}
.expanded-play-scenario-logs-info-container {
  display: flex;
  flex-direction: row;
}
.expanded-play-scenario-logs-info-container .play-scenario-logs {
  max-width: 70%;
  width: 70%;
  flex-shrink: 0.8;
}
.expanded-play-scenario-logs-info-container .play-scenario-info {
  width: 100%;
  text-align: right;
}
.collapsed-play-scenario-logs {
  color: var(--log-color-verbose);
  display: table-row;
  font-size: 9px;
  line-height: 13px;
  white-space: pre-wrap;
  font-family: monospace;
  letter-spacing: -0.05em;
  cursor: pointer;
}
.collapsed-play-scenario-logs:hover {
  color: var(--font-color-hover);
  filter: brightness(1.2);
}