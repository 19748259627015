.tab[data-v-cf2a5e05] {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  font-size: 0.85em;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.tab .indicator-bar[data-v-cf2a5e05] {
  height: 1px;
  position: absolute;
  width: 100%;
  overflow-x: hidden;
}
.tab .indicator-bar.bar[data-v-cf2a5e05] {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}
.tab .indicator-bar.pulsing[data-v-cf2a5e05] {
  animation-name: PulsingBackground-cf2a5e05;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes PulsingBackground-cf2a5e05 {
0% {
    opacity: 0;
}
50% {
    opacity: 1;
}
100% {
    opacity: 0;
}
}
.tab .indicator-bar.progress .progress-bar[data-v-cf2a5e05] {
  height: 1px;
  transition: width ease-in-out 0.5s;
}
.tab .indicator-bar.none[data-v-cf2a5e05] {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.tab .insides[data-v-cf2a5e05] {
  padding-left: 3px;
  padding-top: 1px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}
.tab .insides .icon[data-v-cf2a5e05] {
  font-size: 0.7em;
  padding-inline: 3px;
}
.tab .insides .title[data-v-cf2a5e05] {
  margin-left: 2px;
  white-space: nowrap;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tab .insides .close[data-v-cf2a5e05] {
  font-size: 0.85em;
  padding-left: 2px;
}
.tab.active[data-v-cf2a5e05] {
  background-color: var(--primary-background-color);
}
.tab.top[data-v-cf2a5e05] {
  border-bottom: 1px solid transparent;
}
.tab.top.underline[data-v-cf2a5e05] {
  border-bottom: 1px solid var(--focus-color);
}
.tab.top[data-v-cf2a5e05]:focus {
  border-bottom: 1px solid var(--focus-color);
}
.tab.right[data-v-cf2a5e05] {
  border-left: 1px solid transparent;
}
.tab.right.underline[data-v-cf2a5e05] {
  border-left: 1px solid var(--focus-color);
}
.tab.right[data-v-cf2a5e05]:focus {
  border-left: 1px solid var(--focus-color);
}
.tab.bottom[data-v-cf2a5e05] {
  border-top: 1px solid transparent;
}
.tab.bottom.underline[data-v-cf2a5e05] {
  border-top: 1px solid var(--focus-color);
}
.tab.bottom[data-v-cf2a5e05]:focus {
  border-top: 1px solid var(--focus-color);
}
.tab.left[data-v-cf2a5e05] {
  border-right: 1px solid transparent;
}
.tab.left.underline[data-v-cf2a5e05] {
  border-right: 1px solid var(--focus-color);
}
.tab.left[data-v-cf2a5e05]:focus {
  border-right: 1px solid var(--focus-color);
}
.tab[data-v-cf2a5e05]:hover {
  background-color: var(--primary-background-color);
}