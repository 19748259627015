.container[data-v-f7d49396] {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.actions[data-v-f7d49396] {
  font-size: 0.95em;
  width: 27px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  border-right: 2px solid var(--secondary-background-color);
  align-items: center;
  overflow: auto;
  direction: rtl;
  /*----- Scrollbar CSS  -----*/
}
.actions[data-v-f7d49396]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.actions[data-v-f7d49396]::-webkit-scrollbar-thumb {
  height: 0;
  width: 0;
  border: 0 solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 0;
  background-color: white;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.actions[data-v-f7d49396]::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.actions[data-v-f7d49396]::-webkit-scrollbar-corner {
  background-color: transparent;
}
.actions .top[data-v-f7d49396] {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.actions .padding-middle[data-v-f7d49396] {
  height: 100%;
  width: 100%;
  flex-shrink: 1;
}
.actions .id-tag[data-v-f7d49396] {
  flex-shrink: 0;
  color: var(--font-color-secondary);
  padding-top: 8px;
  padding-bottom: 4px;
  transform: rotate(90deg);
  /* Safari */
  -webkit-transform: rotate(90deg);
  /* Firefox */
  -moz-transform: rotate(90deg);
  font-size: 1.1em;
}
.actions .bottom[data-v-f7d49396] {
  flex-shrink: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.actions .action[data-v-f7d49396] {
  font-size: 16px;
  margin-top: 12px;
  cursor: pointer;
}
.actions .action[data-v-f7d49396]:hover {
  filter: brightness(1.3);
}
.actions .action.stop[data-v-f7d49396], .actions .action.destroy[data-v-f7d49396] {
  color: var(--button-red);
}
.actions .action.pause[data-v-f7d49396] {
  color: var(--button-blue);
}
.actions .action.play[data-v-f7d49396], .actions .action.replay[data-v-f7d49396] {
  color: var(--button-green);
}
.actions .action.decouple-streams[data-v-f7d49396], .actions .action.details[data-v-f7d49396] {
  color: var(--button-disabled);
}
.actions .action.decouple-streams.enabled[data-v-f7d49396], .actions .action.details.enabled[data-v-f7d49396] {
  color: var(--button-green);
}
.actions .action.share[data-v-f7d49396] {
  color: var(--button-green);
}
.actions .action.on-error-cycle[data-v-f7d49396] {
  color: var(--button-red);
}
.actions .action.sticky-scroll[data-v-f7d49396] {
  color: var(--button-white);
}
.actions .action.sticky-scroll.disabled[data-v-f7d49396] {
  color: var(--button-disabled);
}
.status-container[data-v-f7d49396] {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: center;
  font-size: 30px;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.status-container.error[data-v-f7d49396] {
  color: var(--button-red);
}
.status-container .play-logs-container[data-v-f7d49396] {
  display: flex;
  flex-direction: column;
}
.content-container[data-v-f7d49396] {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 27px - 2px);
}
.content-container .plays-container[data-v-f7d49396] {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.content-container .details-container[data-v-f7d49396] {
  padding: 10px;
}
.content-container .details-container .details-table tr > td[data-v-f7d49396] {
  width: 90px;
}
.stream-content[data-v-f7d49396] {
  width: 100%;
  height: 100%;
  overflow: auto;
}