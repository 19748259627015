.header[data-v-06cf94d4] {
  text-align: center;
  font-size: 26px;
  padding-top: 10px;
  padding-bottom: 5px;
}
.body[data-v-06cf94d4] {
  padding: 0 15px 10px;
  font-size: 20px;
}
.body.center[data-v-06cf94d4] {
  text-align: center;
}