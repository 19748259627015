.vue-modal-backdrop[data-v-1139c955] {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
.vue-modal-backdrop .vue-modal[data-v-1139c955] {
  background-color: var(--primary-background-color);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-collapse: separate;
  overflow: visible;
  -webkit-box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.3);
  max-height: 90vh;
  max-width: 90vw;
  display: flex;
  flex-direction: row;
  z-index: 21;
}
.vue-modal-backdrop .vue-modal .vue-modal-sidebar[data-v-1139c955] {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-background-color);
}
.vue-modal-backdrop .vue-modal .vue-modal-content[data-v-1139c955] {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}
.vue-modal-backdrop .vue-modal .vue-modal-content .vue-modal-header[data-v-1139c955] {
  text-align: center;
  font-size: 32px;
}
.vue-modal-backdrop .vue-modal .vue-modal-content .vue-modal-header > div[data-v-1139c955] {
  padding: 15px;
}
.vue-modal-backdrop .vue-modal .vue-modal-content .vue-modal-body[data-v-1139c955] {
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.vue-modal-backdrop .vue-modal .vue-modal-content .buttons-container[data-v-1139c955] {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
}
.vue-modal-backdrop .vue-modal .vue-modal-content .buttons-container .left-box[data-v-1139c955] {
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
}
.vue-modal-backdrop .vue-modal .vue-modal-content .buttons-container .buttons[data-v-1139c955] {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.vue-modal-backdrop .vue-modal .vue-modal-content .buttons-container .right-box[data-v-1139c955] {
  display: flex;
  height: 100%;
  right: 0;
  position: absolute;
}