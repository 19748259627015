label[data-v-6a4b85e9]:not(.disabled) {
  cursor: pointer;
}
label.disabled[data-v-6a4b85e9] {
  cursor: no-drop;
  filter: grayscale(1);
}
label span[data-v-6a4b85e9] {
  line-height: 2.2em;
}
label.vue-label[data-v-6a4b85e9] {
  display: flex;
  align-content: center;
  padding-bottom: 0.3em;
}
label.vue-label.for-table[data-v-6a4b85e9] {
  display: inline-flex;
  min-height: auto;
  transform: scale(0.6);
  margin-top: -8px;
}
label.vue-label.for-table span[data-v-6a4b85e9] {
  height: 18px;
}
[type=checkbox][data-v-6a4b85e9]:not(:checked), [type=checkbox][data-v-6a4b85e9]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
[type=checkbox] + span[data-v-6a4b85e9]:not(.lever) {
  position: relative;
  padding-left: 35px;
  display: inline-block;
  height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
[type=checkbox] + span[data-v-6a4b85e9]:not(.lever):before,
[type=checkbox]:not(.filled-in) + span[data-v-6a4b85e9]:not(.lever):after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid var(--button-disabled);
  border-radius: 1px;
  margin-top: 5px;
  margin-left: 5px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
[type=checkbox]:not(.filled-in) + span[data-v-6a4b85e9]:not(.lever):after {
  border: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type=checkbox]:not(:checked):disabled + span[data-v-6a4b85e9]:not(.lever):before {
  border-color: #5b5b5b;
  background-color: var(--secondary-background-color);
}
[type=checkbox].tabbed:focus + span[data-v-6a4b85e9]:not(.lever):after {
  -webkit-transform: translate(-6px, -7px) scale(1);
  transform: translate(-6px, -7px) scale(1);
  border: 1px solid var(--focus-color);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  width: 34px;
  height: 34px;
}
[type=checkbox]:checked + span[data-v-6a4b85e9]:not(.lever):before {
  top: -7px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-width: 2px;
  border-style: solid;
  -webkit-transform: rotate(37deg);
  transform: rotate(37deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  background: transparent;
}
[type=checkbox]:not(:disabled) + span[data-v-6a4b85e9]:not(.lever):before {
  cursor: pointer;
}
[type=checkbox]:not(:checked) + span[data-v-6a4b85e9]:not(.lever):before {
  background-color: var(--ternary-background-color);
}
[type=checkbox]:checked:disabled + span[data-v-6a4b85e9]:before {
  border-right: 2px solid var(--button-disabled);
  border-bottom: 2px solid var(--button-disabled);
}
[type=checkbox]:indeterminate + span[data-v-6a4b85e9]:not(.lever):before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
[type=checkbox]:indeterminate:disabled + span[data-v-6a4b85e9]:not(.lever):before {
  border-right: 2px solid rgba(0, 0, 0, 0.42);
  background-color: transparent;
  cursor: no-drop;
}
[type=checkbox].filled-in + span[data-v-6a4b85e9]:not(.lever):after {
  border-radius: 2px;
}
[type=checkbox].filled-in + span[data-v-6a4b85e9]:not(.lever):before, [type=checkbox].filled-in + span[data-v-6a4b85e9]:not(.lever):after {
  content: "";
  left: 0;
  position: absolute;
  -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  z-index: 1;
}
[type=checkbox].filled-in:not(:checked) + span[data-v-6a4b85e9]:not(.lever):before {
  width: 0;
  height: 0;
  border-width: 3px;
  border-style: solid;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
[type=checkbox].filled-in:not(:checked) + span[data-v-6a4b85e9]:not(.lever):after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid var(--button-disabled);
  top: 0px;
  z-index: 0;
}
[type=checkbox].filled-in:checked + span[data-v-6a4b85e9]:not(.lever):before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
[type=checkbox].filled-in:checked + span[data-v-6a4b85e9]:not(.lever):after {
  top: 0;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  z-index: 0;
}
[type=checkbox].filled-in.tabbed:focus + span[data-v-6a4b85e9]:not(.lever):after {
  border-radius: 2px;
  border-color: var(--button-disabled);
  background-color: rgba(0, 0, 0, 0.1);
}
[type=checkbox].filled-in.tabbed:checked:focus + span[data-v-6a4b85e9]:not(.lever):after {
  border-radius: 2px;
}
[type=checkbox].filled-in:disabled:not(:checked) + span[data-v-6a4b85e9]:not(.lever):before {
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
}
[type=checkbox].filled-in:disabled:not(:checked) + span[data-v-6a4b85e9]:not(.lever):after {
  border-color: transparent;
  background-color: var(--button-disabled);
}
[type=checkbox].filled-in:disabled:checked + span[data-v-6a4b85e9]:not(.lever):before {
  background-color: transparent;
}
[type=checkbox].filled-in:disabled:checked + span[data-v-6a4b85e9]:not(.lever):after {
  background-color: var(--button-disabled);
  border-color: var(--button-disabled);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever):after.default[data-v-6a4b85e9],
[type=checkbox].filled-in:checked + span:not(.lever):after.default[data-v-6a4b85e9] {
  background-color: var(--secondary-background-color);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever):after.grey[data-v-6a4b85e9],
[type=checkbox].filled-in:checked + span:not(.lever):after.grey[data-v-6a4b85e9] {
  background-color: var(--button-grey);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever):after.red[data-v-6a4b85e9],
[type=checkbox].filled-in:checked + span:not(.lever):after.red[data-v-6a4b85e9] {
  background-color: var(--button-red);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever):after.green[data-v-6a4b85e9],
[type=checkbox].filled-in:checked + span:not(.lever):after.green[data-v-6a4b85e9] {
  background-color: var(--button-green);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever):after.blue[data-v-6a4b85e9],
[type=checkbox].filled-in:checked + span:not(.lever):after.blue[data-v-6a4b85e9] {
  background-color: var(--button-blue);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever):after.white[data-v-6a4b85e9],
[type=checkbox].filled-in:checked + span:not(.lever):after.white[data-v-6a4b85e9] {
  background-color: var(--button-white);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever).default[data-v-6a4b85e9]:after,
[type=checkbox].filled-in:checked + span:not(.lever).default[data-v-6a4b85e9]:after {
  border-color: var(--secondary-background-color);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever).grey[data-v-6a4b85e9]:after,
[type=checkbox].filled-in:checked + span:not(.lever).grey[data-v-6a4b85e9]:after {
  border-color: var(--button-grey);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever).red[data-v-6a4b85e9]:after,
[type=checkbox].filled-in:checked + span:not(.lever).red[data-v-6a4b85e9]:after {
  border-color: var(--button-red);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever).green[data-v-6a4b85e9]:after,
[type=checkbox].filled-in:checked + span:not(.lever).green[data-v-6a4b85e9]:after {
  border-color: var(--button-green);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever).blue[data-v-6a4b85e9]:after,
[type=checkbox].filled-in:checked + span:not(.lever).blue[data-v-6a4b85e9]:after {
  border-color: var(--button-blue);
}
[type=checkbox].filled-in.tabbed:checked:focus + span:not(.lever).white[data-v-6a4b85e9]:after,
[type=checkbox].filled-in:checked + span:not(.lever).white[data-v-6a4b85e9]:after {
  border-color: var(--button-white);
}
[type=checkbox]:indeterminate + span:not(.lever).default[data-v-6a4b85e9]:before,
[type=checkbox]:checked + span:not(.lever).default[data-v-6a4b85e9]:before {
  border-right-color: var(--secondary-background-color);
}
[type=checkbox]:indeterminate + span:not(.lever).grey[data-v-6a4b85e9]:before,
[type=checkbox]:checked + span:not(.lever).grey[data-v-6a4b85e9]:before {
  border-right-color: var(--button-grey);
}
[type=checkbox]:indeterminate + span:not(.lever).red[data-v-6a4b85e9]:before,
[type=checkbox]:checked + span:not(.lever).red[data-v-6a4b85e9]:before {
  border-right-color: var(--button-red);
}
[type=checkbox]:indeterminate + span:not(.lever).green[data-v-6a4b85e9]:before,
[type=checkbox]:checked + span:not(.lever).green[data-v-6a4b85e9]:before {
  border-right-color: var(--button-green);
}
[type=checkbox]:indeterminate + span:not(.lever).blue[data-v-6a4b85e9]:before,
[type=checkbox]:checked + span:not(.lever).blue[data-v-6a4b85e9]:before {
  border-right-color: var(--button-blue);
}
[type=checkbox]:indeterminate + span:not(.lever).white[data-v-6a4b85e9]:before,
[type=checkbox]:checked + span:not(.lever).white[data-v-6a4b85e9]:before {
  border-right-color: var(--button-white);
}
[type=checkbox]:checked + span:not(.lever).default[data-v-6a4b85e9]:before {
  border-bottom-color: var(--secondary-background-color);
  border-right-color: var(--secondary-background-color);
}
[type=checkbox]:checked + span:not(.lever).grey[data-v-6a4b85e9]:before {
  border-bottom-color: var(--button-grey);
  border-right-color: var(--button-grey);
}
[type=checkbox]:checked + span:not(.lever).red[data-v-6a4b85e9]:before {
  border-bottom-color: var(--button-red);
  border-right-color: var(--button-red);
}
[type=checkbox]:checked + span:not(.lever).green[data-v-6a4b85e9]:before {
  border-bottom-color: var(--button-green);
  border-right-color: var(--button-green);
}
[type=checkbox]:checked + span:not(.lever).blue[data-v-6a4b85e9]:before {
  border-bottom-color: var(--button-blue);
  border-right-color: var(--button-blue);
}
[type=checkbox]:checked + span:not(.lever).white[data-v-6a4b85e9]:before {
  border-bottom-color: var(--button-white);
  border-right-color: var(--button-white);
}