.phonector-row[data-v-ac6068c3] {
  cursor: pointer;
}
.phonector-row .action[data-v-ac6068c3] {
  cursor: pointer;
  width: 10px;
  display: flex;
  justify-content: center;
}
.phonector-row .action.delete[data-v-ac6068c3] {
  color: var(--button-red);
}
.expanded-td[data-v-ac6068c3] {
  padding-left: 20px;
}