span.vue-action-icon-container[data-v-2f172ccf] {
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  aspect-ratio: 1/1;
  margin-inline: 0.1em;
  font-size: 1.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
}
span.vue-action-icon-container .loading-container[data-v-2f172ccf] {
  min-width: 0;
  width: 100%;
  overflow: hidden;
}
span.vue-action-icon-container span.vue-action-icon[data-v-2f172ccf] {
  margin: 0.25em;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  border-radius: 50%;
}
span.vue-action-icon-container span.vue-action-icon.disabled[data-v-2f172ccf] {
  cursor: not-allowed;
  color: var(--button-disabled) !important;
}
span.vue-action-icon-container span.vue-action-icon[data-v-2f172ccf]:not(.disabled):hover, span.vue-action-icon-container span.vue-action-icon[data-v-2f172ccf]:not(.disabled):focus {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.55);
  background-color: var(--primary-background-color);
  filter: brightness(1.3);
}
span.vue-action-icon-container span.vue-action-icon i[data-v-2f172ccf] {
  margin: 0.25em;
}
span.vue-action-icon-container.default[data-v-2f172ccf] {
  color: var(--secondary-background-color);
}
span.vue-action-icon-container.grey[data-v-2f172ccf] {
  color: var(--button-grey);
}
span.vue-action-icon-container.red[data-v-2f172ccf] {
  color: var(--button-red);
}
span.vue-action-icon-container.green[data-v-2f172ccf] {
  color: var(--button-green);
}
span.vue-action-icon-container.blue[data-v-2f172ccf] {
  color: var(--button-blue);
}
span.vue-action-icon-container.white[data-v-2f172ccf] {
  color: var(--button-white);
}