.vue-settings-item-container[data-v-a9389bac] {
  height: inherit;
  padding-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
}
.vue-settings-item-container .vue-settings-item-header[data-v-a9389bac] {
  height: 40px;
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
}
.vue-settings-item-container .vue-settings-item-header .vue-settings-item-title[data-v-a9389bac] {
  width: 100%;
  text-align: center;
  font-size: 18px;
}
.vue-settings-item-container .vue-settings-item-body[data-v-a9389bac] {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}