.codemirror-other-activity-cursor[data-v-4f071345] {
  border-left: 2px solid var(--secondary-background-color);
  border-right: none;
  width: 0;
  height: 1.2em;
  position: absolute;
  pointer-events: none;
  z-index: 5;
}
.codemirror-other-activity-cursor .codemirror-other-activity-cursor-names[data-v-4f071345] {
  white-space: nowrap;
  background-color: var(--secondary-background-color);
  padding: 2px 4px 2px 4px;
  border-radius: 10px;
  width: min-content;
}