#play_modal .header {
  display: flex;
  flex-direction: row;
  padding: 0;
}
#play_modal .header .scenarios-count {
  flex-shrink: 0;
  width: 50px;
}
#play_modal .header .title {
  text-align: center;
  font-size: 25px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
#play_modal .header .modules-activation-container {
  position: relative;
  flex-shrink: 0;
  width: 50px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
#play_modal .header .modules-activation-container .modules-activation {
  right: 0;
  position: absolute;
  display: flex;
  font-size: 18px;
  align-items: baseline;
}
#play_modal .header .modules-activation-container .modules-activation span {
  cursor: pointer;
  margin-inline: 4px;
  font-size: 20px;
}
#play_modal .body .phone-apps-container .row {
  margin-bottom: 0;
}
#play_modal .body .phone-apps-container .row .add-remove-item-container {
  left: -30px;
}
#play_modal .body .row {
  margin-bottom: 10px;
  position: relative;
}
#play_modal .body .row .add-remove-item-container {
  display: none;
  height: 100%;
  width: 25px;
  position: absolute;
  left: -25px;
  justify-content: center;
  align-items: end;
}
#play_modal .body .row.play-settings .col {
  min-height: 55px;
  align-items: end;
}
#play_modal .body .row.play-settings .schedule-settings {
  flex-direction: row;
  align-items: center;
}
#play_modal .body .row.play-settings .schedule-settings div.schedule-setting-item {
  margin-inline: 5px;
  width: 100%;
}
#play_modal .body .edit-variable-set {
  cursor: pointer;
  aspect-ratio: 1;
  display: flex;
  margin-top: 18px;
  font-size: 1.3em;
}
#play_modal .body.advanced-play .row {
  margin-left: 25px;
  width: calc(100% - 25px);
}
#play_modal .body.advanced-play .row .add-remove-item-container {
  display: flex;
  align-items: center;
}
#play_modal .body.advanced-play .row .add-remove-item-container.padded {
  top: 5px;
}
#play_modal .body.advanced-play .row .advanced-item {
  display: flex;
}
#play_modal .curl-request {
  color: var(--font-color-secondary);
  font-size: 0.6em;
  cursor: pointer;
  align-self: end;
}
#play_modal .first-row-label-padding {
  padding-top: 7px;
}
#play_modal .browser-is-mobile-toggle,
#play_modal .mobile-type-toggle {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
  font-size: 1.3em;
}
#play_modal .save-icon {
  padding-right: 12px;
  font-size: 1.2em;
}
#play_modal .save-icon:not(.invalid) {
  cursor: pointer;
  color: var(--button-green);
}
#play_modal .save-icon.invalid {
  cursor: not-allowed;
  color: var(--button-red);
}