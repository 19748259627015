.feature-flag-edit[data-v-812a7099] {
  display: flex;
  flex-direction: column;
}
.feature-flag-edit .header[data-v-812a7099] {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.feature-flag-edit .body[data-v-812a7099] {
  margin-top: 10px;
}