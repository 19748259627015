.container[data-v-081a81f7] {
  display: flex;
  flex-direction: row;
  width: 600px;
  height: calc(100% - 1px);
}
.container .alternative-sign-in-container[data-v-081a81f7] {
  display: flex;
  flex-direction: row;
  width: 55%;
  height: fit-content;
}
.container .alternative-sign-in-container .alternative-sign-in[data-v-081a81f7] {
  display: flex;
  padding: 10px 20px 20px 20px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container .alternative-sign-in-container .divider[data-v-081a81f7] {
  margin-left: 10px;
  height: auto;
  margin-bottom: 30px;
  min-width: 2px;
  min-height: 100%;
  width: 2px;
  display: flex;
  flex-direction: column;
}
.container .alternative-sign-in-container .divider .bar[data-v-081a81f7] {
  height: 40%;
  min-width: 2px;
  width: 2px;
  background: var(--ternary-background-color);
}
.container .alternative-sign-in-container .divider .or[data-v-081a81f7] {
  margin-block: 10px;
  margin-left: -5px;
}
.container .company-sign-in-container[data-v-081a81f7] {
  display: flex;
  flex-direction: row;
  width: 45%;
  justify-content: center;
  align-items: start;
  height: 100%;
}
.container .company-sign-in-container .company-container[data-v-081a81f7] {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container .company-sign-in-container .company-container .name[data-v-081a81f7] {
  font-size: 0.8em;
}
.container .company-sign-in-container .company-container:hover .name[data-v-081a81f7] {
  text-decoration: underline;
}