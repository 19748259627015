.scope-container[data-v-9ce6f0c3] {
  padding: 7px 10px;
  border-right: 1px solid var(--border-color);
  font-size: 1.1em;
  color: var(--font-color-secondary);
  cursor: pointer;
}
.scope-container[data-v-9ce6f0c3]:hover, .scope-container.selected[data-v-9ce6f0c3] {
  color: var(--font-color);
  background-color: var(--ternary-background-color);
}