.sidebar[data-v-0d67d30a] {
  width: 200px;
  display: flex;
  flex-direction: column;
}
.sidebar .container[data-v-0d67d30a] {
  height: 100%;
  padding: 20px;
  display: flex;
  background-color: var(--secondary-background-color);
  flex-direction: column;
  max-height: 60vh;
  overflow: auto;
}
.body[data-v-0d67d30a] {
  width: 65vw;
  height: 60vh;
  overflow: auto;
}
.body .content[data-v-0d67d30a] {
  height: 100%;
  width: 100%;
  overflow: hidden;
}