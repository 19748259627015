.groups-scenario[data-v-0fa00575] {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9em;
  cursor: pointer;
}
.groups-scenario .real-order-text[data-v-0fa00575] {
  margin-right: 5px;
}
.groups-scenario .scenario-name[data-v-0fa00575] {
  color: var(--button-blue);
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.groups-scenario .actions[data-v-0fa00575] {
  display: flex;
  flex-direction: row;
  pointer-events: none;
  opacity: 0;
  flex-shrink: 0;
}
.groups-scenario[data-v-0fa00575]:hover {
  background-color: var(--ternary-background-color);
}
.groups-scenario:hover .actions[data-v-0fa00575] {
  pointer-events: auto;
  opacity: 1;
}
.groups-scenario:hover .scenario-name[data-v-0fa00575] {
  text-decoration: underline;
}