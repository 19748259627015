.node[data-v-b104d399] {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.node .content[data-v-b104d399] {
  display: flex;
  align-items: center;
  min-height: 18px;
  margin: 1px;
  width: 100%;
  background-color: var(--primary-background-color);
  flex-basis: max-content;
  flex-shrink: 0;
  flex-grow: 1;
}
.node .content[data-v-b104d399]:hover {
  background-color: var(--sidebar-hover);
}
.node .content.active[data-v-b104d399] {
  background-color: var(--sidebar-selected);
}
.node .content.selected[data-v-b104d399] {
  background-color: var(--sidebar-selected);
}
.node .content .level-padding[data-v-b104d399] {
  flex-shrink: 0;
}
.node .content .expander[data-v-b104d399] {
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  width: 15px;
  flex-shrink: 0;
}
.node .content .icon-container[data-v-b104d399] {
  display: flex;
  width: 10px;
  justify-content: center;
  padding-left: 3px;
  padding-right: 5px;
  font-size: 0.9em;
  flex-shrink: 0;
}
.node .content .title[data-v-b104d399] {
  white-space: nowrap;
}
.node .content .node-content-expander[data-v-b104d399] {
  flex-shrink: 9999;
  width: 100%;
  min-width: 10px;
}
.node .content .hover-action-anchor[data-v-b104d399] {
  position: sticky;
  display: none;
  justify-content: center;
  align-items: center;
  width: 0;
  right: 0;
}
.node .content .hover-action-anchor .hover-action[data-v-b104d399] {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  margin-left: -30px;
  opacity: 75%;
  border-radius: 50%;
  background-color: var(--primary-background-color);
}
.node .content .hover-action-anchor .hover-action[data-v-b104d399]:hover {
  opacity: 100%;
}
.node .content:hover .hover-action-anchor[data-v-b104d399] {
  display: flex;
}
.node .content.no-drop[data-v-b104d399] {
  opacity: 0.3;
}
.node .content.is-cut[data-v-b104d399] {
  opacity: 0.3;
  border-left: 1px solid var(--button-blue);
}
.node .content.is-copied[data-v-b104d399] {
  border-left: 1px solid var(--button-blue);
}
.children[data-v-b104d399] {
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 100%;
}