.vue-table-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.vue-table-container .action-width {
  width: 40px !important;
}
.vue-table-container .vue-thead-container {
  position: relative;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
  flex-shrink: 0;
}
.vue-table-container .vue-thead-container::-webkit-scrollbar {
  display: none;
}
.vue-table-container .vue-thead tr {
  display: inline-flex;
}
.vue-table-container .vue-thead tr th {
  flex-shrink: 0;
  position: relative;
}
.vue-table-container .vue-thead tr th.orderable {
  cursor: pointer;
}
.vue-table-container .vue-thead tr th.orderable:hover {
  text-decoration: underline;
}
.vue-table-container .vue-thead tr th.asc::before {
  content: attr(data-priority);
  position: absolute;
  right: 7px;
  top: 0;
  border-bottom: 0.5em solid var(--font-color);
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
  font-size: 0.75em;
}
.vue-table-container .vue-thead tr th.desc::before {
  content: attr(data-priority);
  position: absolute;
  right: 7px;
  top: 10%;
  border-top: 0.5em solid var(--font-color);
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
  font-size: 0.75em;
}
.vue-table-container .vue-thead,
.vue-table-container thead {
  width: 100%;
}
.vue-table-container .vue-thead.underline-header,
.vue-table-container thead.underline-header {
  border-bottom: 1px solid var(--font-color);
}
.vue-table-container .vue-thead tr th,
.vue-table-container thead tr th {
  overflow: auto;
  vertical-align: bottom;
  padding-right: 5px;
  font-size: 14px;
  text-align: left;
  scrollbar-width: none;
}
.vue-table-container .vue-thead tr th::-webkit-scrollbar,
.vue-table-container thead tr th::-webkit-scrollbar {
  display: none;
}
.vue-table-container .vue-table-scroll {
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 0;
}
.vue-table-container .vue-table-scroll .vue-table {
  width: 100%;
  border-collapse: collapse;
}
.vue-table-container .vue-table-scroll .vue-table thead {
  border: none;
  visibility: collapse;
  color: transparent;
  background: transparent;
  filter: opacity(0);
  pointer-events: none;
}
.vue-table-container .vue-table-scroll .vue-table tbody tr:hover {
  background-color: var(--primary-background-color);
  filter: brightness(1.2);
  border-radius: 2px;
}
.vue-table-container .vue-table-scroll .vue-table tbody tr .filter-highlight {
  background-color: var(--table-filter-highlight);
}