.user-edit[data-v-a5b9627d] {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}
.user-edit .header[data-v-a5b9627d] {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.user-edit .body[data-v-a5b9627d] {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}