.snapshot-container[data-v-7e3dedf0] {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
.snapshot-container .snapshot-inspector-container[data-v-7e3dedf0] {
  position: absolute;
  margin: auto;
}
.snapshot-container img[data-v-7e3dedf0] {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}