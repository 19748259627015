.tab-content[data-v-358c822b] {
  width: 100%;
  height: 100%;
}
.tab-content .error-container[data-v-358c822b] {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tab-content .error-container .backtrace[data-v-358c822b] {
  max-height: 70%;
  overflow: auto;
}