.snippet-history-container[data-v-fa24e59d] {
  width: 85vw;
  height: 80vh;
  max-height: 80vh;
  max-width: 85vw;
  resize: both;
  overflow: auto;
}
.snippet-history-container .items-container[data-v-fa24e59d] {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background-color: var(--ternary-background-color);
  padding: 5px;
  overflow: auto;
}
.snippet-history-container .items-container .result[data-v-fa24e59d] {
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.snippet-history-container .items-container .result .datetime-info[data-v-fa24e59d] {
  font-size: 0.7em;
  display: flex;
  flex-direction: row;
}
.snippet-history-container .items-container .result .datetime-info .separator[data-v-fa24e59d] {
  margin-inline: 5px;
}
.snippet-history-container .items-container .result .snippet-name[data-v-fa24e59d] {
  margin-top: 2px;
  margin-bottom: 2px;
}
.snippet-history-container .items-container .result .user-info[data-v-fa24e59d] {
  color: var(--font-color-secondary);
  font-size: 0.65em;
  display: flex;
  flex-direction: column;
}
.snippet-history-container .items-container .result.active[data-v-fa24e59d] {
  background-color: var(--primary-background-color);
}
.snippet-history-container .items-container .result[data-v-fa24e59d]:hover {
  background-color: var(--primary-background-color);
}