.project-version-edit[data-v-76306157] {
  display: flex;
  flex-direction: column;
}
.project-version-edit .header[data-v-76306157] {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.project-version-edit .body[data-v-76306157] {
  margin-top: 10px;
}