.vue-tabs-and-content-container[data-v-0a2b21b5] {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.vue-tabs-and-content-container .vue-tabs-container[data-v-0a2b21b5] {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;
  overflow-x: auto;
  overflow-y: visible;
  flex-shrink: 0;
  align-items: end;
}
.vue-tabs-and-content-container .vue-tabs-container.round .vue-tab[data-v-0a2b21b5] {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.vue-tabs-and-content-container .vue-tabs-container .vue-tab[data-v-0a2b21b5] {
  min-width: 50px;
  height: 30px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-shrink: 1;
  color: var(--font-color-secondary);
  cursor: pointer;
  background-color: var(--ternary-background-color);
  border-bottom: 2px solid var(--secondary-background-color) !important;
  transition: filter 0.25s, background-color 0.25s, height 0.25s;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.vue-tabs-and-content-container .vue-tabs-container .vue-tab.tabDisabled[data-v-0a2b21b5] {
  cursor: not-allowed;
}
.vue-tabs-and-content-container .vue-tabs-container .vue-tab.selected[data-v-0a2b21b5] {
  background: var(--secondary-background-color);
  height: 35px;
}
.vue-tabs-and-content-container .vue-tabs-container .vue-tab.selected[data-v-0a2b21b5], .vue-tabs-and-content-container .vue-tabs-container .vue-tab[data-v-0a2b21b5]:not(.tabDisabled):hover {
  color: var(--font-color);
}
.vue-tabs-and-content-container .vue-tabs-container .vue-tab[data-v-0a2b21b5]:not(.tabDisabled):not(.selected):hover {
  filter: brightness(1.2);
}
.vue-tabs-and-content-container .vue-tabs-container .vue-tab[data-v-0a2b21b5]:focus {
  border-left: 1px solid var(--focus-color);
  border-right: 1px solid var(--focus-color);
  border-top: 1px solid var(--focus-color);
}
.vue-tabs-and-content-container .vue-tabs-content-container[data-v-0a2b21b5] {
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: auto;
}