.inspector[data-v-514d0881] {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 8;
}
.inspector.flash-white[data-v-514d0881] {
  transition: box-shadow 0.75s;
  transition-timing-function: ease-in;
  box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.91);
}
.inspector.fade[data-v-514d0881] {
  transition: box-shadow 0.75s;
  transition-timing-function: ease-in;
  box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0) !important;
}
.inspector.flash-blue[data-v-514d0881] {
  transition: box-shadow 0.5s;
  transition-timing-function: ease-in;
  box-shadow: 0 0 6px 3px rgba(0, 83, 203, 0.91);
}
.inspector .elements-container[data-v-514d0881] {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
.clip-overlay[data-v-514d0881] {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: var(--overlay-background-color);
}
.clicked-dot[data-v-514d0881] {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  border: 1px solid var(--button-white);
  background: var(--button-disabled);
  position: absolute;
  z-index: 9999;
}