.add-user-project[data-v-e9ee41ee] {
  cursor: pointer;
}
.action-td[data-v-e9ee41ee] {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-td div[data-v-e9ee41ee] {
  width: 25px;
  height: 15px;
}
.action-td .user-project-remove[data-v-e9ee41ee] {
  color: var(--button-red);
  cursor: pointer;
}