.header[data-v-66efb101] {
  height: 26px;
  width: 100vw;
  margin: 0;
  background-color: var(--secondary-background-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8em;
  z-index: 6;
  position: relative;
}
.header .language-icon-container[data-v-66efb101] {
  margin-inline: 4px;
  font-size: 1.1em;
}
.header .project-select-container[data-v-66efb101] {
  margin-inline: 4px;
  width: 200px;
}
.header .project-version-select-container[data-v-66efb101] {
  margin-inline: 4px;
  width: 200px;
}
.header .role-container[data-v-66efb101] {
  margin-inline: 4px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.header .role-container span[data-v-66efb101] {
  margin-inline: 4px;
}
.header .expander[data-v-66efb101] {
  width: 100%;
  flex-shrink: 9999;
}
.header .webs-counter-container[data-v-66efb101] {
  white-space: nowrap;
  margin-inline: 4px;
}
.header .connection-status[data-v-66efb101] {
  margin-inline: 4px;
  cursor: pointer;
}
.header .connection-status.online[data-v-66efb101] {
  color: var(--button-green);
}
.header .connection-status.under-load[data-v-66efb101] {
  color: var(--button-orange);
}
.header .connection-status.offline[data-v-66efb101] {
  color: var(--button-red);
}
.header .icons-container[data-v-66efb101] {
  font-size: 0.9em;
  display: flex;
  flex-direction: row;
  margin-inline: 4px;
}