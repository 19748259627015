.container[data-v-1323bc2d] {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}
.container .application-container[data-v-1323bc2d] {
  height: 100%;
  min-height: 0;
  background-color: var(--primary-background-color);
  display: flex;
  flex-direction: row;
}
.container .application-container .no-project-info-container[data-v-1323bc2d] {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  gap: 1em;
}
.container .application-container .no-project-info-container .icon[data-v-1323bc2d] {
  font-size: 5em;
}
.container .application-container .no-project-info-container .message[data-v-1323bc2d] {
  text-align: center;
}