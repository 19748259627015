.bar[data-v-a0a178ba] {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 6;
}
.bar .progress[data-v-a0a178ba] {
  width: 0;
  height: 100%;
  transition: height 0.3s, width 0.2s;
}