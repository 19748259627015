.worker-row[data-v-d70d52b1] {
  cursor: pointer;
}
.worker-row td.green[data-v-d70d52b1] {
  color: var(--button-green);
}
.worker-row td.red[data-v-d70d52b1] {
  color: var(--button-red);
}
.worker-row td.yellow[data-v-d70d52b1] {
  color: var(--button-yellow);
}
.worker-log-row[data-v-d70d52b1] {
  font-size: 0.8em;
}
.worker-count-controls-container[data-v-d70d52b1] {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
}
.worker-count-controls-container .count-tag-display[data-v-d70d52b1] {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--font-color-secondary);
  margin-inline: 10px;
}
.worker-count-controls-container .count-control[data-v-d70d52b1] {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  border-radius: 50%;
}
.worker-count-controls-container .count-control[data-v-d70d52b1]:hover, .worker-count-controls-container .count-control[data-v-d70d52b1]:focus {
  background-color: var(--primary-background-color);
  filter: brightness(1.2);
}
.worker-count-controls-container .count-control.red[data-v-d70d52b1] {
  color: var(--button-red);
}
.worker-count-controls-container .count-control.green[data-v-d70d52b1] {
  color: var(--button-green);
}