label.input-label[data-v-a3f108c3] {
  position: relative;
  min-height: 0;
  display: flex;
  width: 100%;
}
label.input-label[data-v-a3f108c3]:hover, label.input-label[data-v-a3f108c3]:focus-within {
  filter: brightness(1.075);
}
label.input-label input[data-v-a3f108c3] {
  color: var(--font-color);
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--ternary-background-color);
  padding: 0.2em 8px;
  border-radius: 5px;
  width: 100%;
  outline: none;
  line-height: normal;
  font-size: 1em;
  height: 100%;
  border-width: 1px;
  border-style: solid;
}
label.input-label input[data-v-a3f108c3]::-webkit-outer-spin-button, label.input-label input[data-v-a3f108c3]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
label.input-label input[type=number][data-v-a3f108c3] {
  -moz-appearance: textfield;
}
label.input-label input[data-v-a3f108c3]:disabled {
  cursor: not-allowed;
}
label.input-label .placeholder[data-v-a3f108c3] {
  background-color: var(--ternary-background-color);
  position: absolute;
  left: 8px;
  width: calc(100% - 16px - 5px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 1em;
  cursor: text;
  line-height: 100%;
  pointer-events: none;
  transform: translateY(-50%);
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
}
label.input-label .placeholder.disabled[data-v-a3f108c3] {
  cursor: not-allowed;
}
label.input-label .clear-action[data-v-a3f108c3] {
  top: 2px;
  right: -2px;
  font-size: 1.25em;
  height: calc(100% - 4px);
  position: absolute;
  color: var(--button-red);
}
label.input-label input + .placeholder[data-v-a3f108c3] {
  left: 8px;
  padding: 0 5px;
}
label.input-label input.dirty + .placeholder[data-v-a3f108c3],
label.input-label input:not(:placeholder-shown) + .placeholder[data-v-a3f108c3],
label.input-label input:focus + .placeholder[data-v-a3f108c3] {
  top: 0;
  font-size: 0.72em;
  width: auto;
  cursor: auto;
}
label.input-label input:disabled.dirty[data-v-a3f108c3],
label.input-label input[data-v-a3f108c3]:disabled:not(:placeholder-shown),
label.input-label input[data-v-a3f108c3]:disabled:focus {
  border-color: var(--font-color-secondary);
  transition-delay: 0.1s;
}
label.input-label input:disabled.dirty + .placeholder[data-v-a3f108c3],
label.input-label input:disabled:not(:placeholder-shown) + .placeholder[data-v-a3f108c3],
label.input-label input:disabled:focus + .placeholder[data-v-a3f108c3] {
  top: 0;
  font-size: 0.72em;
  color: var(--font-color-secondary);
  width: auto;
}
label.input-label:not(.invalid) input[data-v-a3f108c3] {
  border-color: var(--secondary-background-color);
}
label.input-label:not(.invalid) .placeholder[data-v-a3f108c3] {
  color: var(--font-color-secondary);
}
label.input-label:not(.invalid) input:not(:focus).dirty[data-v-a3f108c3] {
  border-color: var(--secondary-background-color);
}
label.input-label:not(.invalid) input.dirty + .placeholder[data-v-a3f108c3],
label.input-label:not(.invalid) input:not(:placeholder-shown) + .placeholder[data-v-a3f108c3],
label.input-label:not(.invalid) input:focus + .placeholder[data-v-a3f108c3] {
  color: var(--font-color);
}
label.input-label.invalid input[data-v-a3f108c3] {
  border-color: var(--button-red);
}
label.input-label.invalid .placeholder[data-v-a3f108c3] {
  color: var(--button-red);
}
label.input-label.invalid input.dirty + .placeholder[data-v-a3f108c3],
label.input-label.invalid input:not(:placeholder-shown) + .placeholder[data-v-a3f108c3],
label.input-label.invalid input:focus + .placeholder[data-v-a3f108c3] {
  color: var(--button-red);
}