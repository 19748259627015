.element-info[data-v-9c6d9212] {
  display: flex;
  flex-direction: column;
}
.element-info .label[data-v-9c6d9212] {
  display: flex;
  flex-direction: row;
  font-size: 0.85em;
  align-items: baseline;
  overflow: hidden;
}
.element-info .label .dot[data-v-9c6d9212] {
  width: 0.3em;
  height: 0.3em;
  border-radius: 50%;
  border-width: 0.2em;
  border-style: solid;
  flex-shrink: 0;
}
.element-info .label .type[data-v-9c6d9212] {
  margin-left: 5px;
}
.element-info .label .hide-element-action[data-v-9c6d9212] {
  width: 100%;
  font-size: 0.9em;
  text-align: center;
  color: var(--font-color-secondary);
  cursor: pointer;
}
.element-info .label .hide-element-action[data-v-9c6d9212]:hover {
  text-decoration: underline;
  color: var(--font-color);
}
.element-info .label .not-in-viewport[data-v-9c6d9212] {
  color: var(--button-purple);
  width: 100%;
  font-size: 0.9em;
  text-align: center;
}
.element-info .label .attributes-toggle[data-v-9c6d9212] {
  text-align: right;
}
.element-info .label .attributes-toggle .btn[data-v-9c6d9212] {
  cursor: pointer;
  font-size: 0.9em;
  color: var(--font-color-secondary);
}
.element-info .label .attributes-toggle .btn[data-v-9c6d9212]:hover {
  text-decoration: underline;
  color: var(--font-color);
}