.scenario-editor-container[data-v-a55b25fe] {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.scenario-editor-container .overlay[data-v-a55b25fe] {
  position: absolute;
  pointer-events: none;
  display: flex;
  width: 100%;
  z-index: 5;
}
.scenario-editor-container .overlay .left-corner[data-v-a55b25fe] {
  pointer-events: auto;
  position: absolute;
  display: flex;
  left: 0;
}
.scenario-editor-container .overlay .right-corner[data-v-a55b25fe] {
  pointer-events: auto;
  display: flex;
  position: absolute;
  right: 0;
}
.scenario-editor-container .canvas-target[data-v-a55b25fe] {
  padding-inline: 4.5px;
  width: calc(100% - 9px);
  height: 100%;
}