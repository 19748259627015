.container[data-v-b3ec90da] {
  overflow: auto;
}
.table-wrapper[data-v-b3ec90da] {
  min-height: fit-content;
}
.file-purge-icon[data-v-b3ec90da] {
  font-size: 1.1em;
  color: var(--button-red);
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.file-purge-icon[data-v-b3ec90da]:hover, .file-purge-icon[data-v-b3ec90da]:focus {
  background-color: var(--primary-background-color);
  filter: brightness(1.2);
}
.file-info[data-v-b3ec90da] {
  font-size: 0.7em;
}