.element[data-v-211e514e] {
  position: absolute;
  pointer-events: all;
  border: 1px solid transparent;
  outline: 2px solid transparent;
}
.element[data-v-211e514e]:hover, .element.highlighted[data-v-211e514e] {
  /*background-color: rgba(255, 255, 0, 0.24);*/
  border: 1px solid var(--secondary-light-background-color);
  outline: 2px solid var(--secondary-dark-background-color);
}
.element.filter-match[data-v-211e514e] {
  outline: 1px solid var(--button-yellow) !important;
  pointer-events: all;
}
.element.filter-nomatch[data-v-211e514e] {
  pointer-events: none;
}
.element.selected[data-v-211e514e] {
  /*background-color: rgba(113, 11, 255, 0.38);*/
  outline: 2px solid var(--button-blue);
}