.inspector-side-kit[data-v-6bdf2fe4] {
  position: absolute;
  pointer-events: auto;
  height: calc(100% + 3px);
  top: -2px;
  background: var(--primary-background-color);
  z-index: 9997;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.inspector-side-kit.left[data-v-6bdf2fe4], .inspector-side-kit.right[data-v-6bdf2fe4] {
  width: 100%;
  border-top: 2px solid var(--border-color);
  border-bottom: 2px solid var(--border-color);
}
.inspector-side-kit.left[data-v-6bdf2fe4] {
  right: calc(100% + 2px);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 2px solid var(--border-color);
  -webkit-box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.25);
}
.inspector-side-kit.right[data-v-6bdf2fe4] {
  left: calc(100% + 2px);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 2px solid var(--border-color);
  -webkit-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.25);
}
.inspector-side-kit .title-container[data-v-6bdf2fe4] {
  font-size: 0.85em;
  white-space: nowrap;
  padding: 5px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}
.inspector-side-kit .title-container .title[data-v-6bdf2fe4] {
  overflow: hidden;
  text-overflow: ellipsis;
}
.inspector-side-kit .title-container .close[data-v-6bdf2fe4] {
  width: 25px;
  justify-content: end;
  align-items: start;
  flex-shrink: 0;
}
.inspector-side-kit .side-kit-content[data-v-6bdf2fe4] {
  height: calc(100% - 20px - 10px);
  flex-shrink: 0;
}
.inspector-side-kit .side-kit-content .info-grid-area[data-v-6bdf2fe4] {
  padding: 5px 0 5px 5px;
  width: calc(100% - 5px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.inspector-side-kit .side-kit-content .info-grid-area .snapshot-info-container[data-v-6bdf2fe4] {
  font-size: 10px;
  width: calc(100% - 6px);
  height: 12px;
  display: flex;
  flex-direction: row;
  padding-inline: 3px;
  justify-content: space-between;
}
.inspector-side-kit .side-kit-content .info-grid-area .snapshot-info-container .snapshot-info .snapshot-info-label[data-v-6bdf2fe4] {
  color: var(--font-color-secondary);
}
.inspector-side-kit .side-kit-content .info-grid-area .snapshot-info-container .snapshot-info .snapshot-info-value[data-v-6bdf2fe4] {
  color: var(--font-color);
}
.inspector-side-kit .side-kit-content .info-grid-area .filter[data-v-6bdf2fe4] {
  margin-bottom: 10px;
}
.inspector-side-kit .side-kit-content .info-grid-area .info-container[data-v-6bdf2fe4] {
  margin-block: 5px;
  padding-block: 5px;
  padding-inline: 4px;
  border-radius: 8px;
  background: var(--ternary-background-color);
}
.inspector-side-kit .side-kit-content .info-grid-area .mouse-n-click-information[data-v-6bdf2fe4] {
  height: 15px;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  padding-inline: 3px;
}
.inspector-side-kit .side-kit-content .info-grid-area .mouse-n-click-information .coords-container[data-v-6bdf2fe4] {
  cursor: pointer;
}
.inspector-side-kit .side-kit-content .info-grid-area .mouse-n-click-information .coords-container:hover .copy-icon[data-v-6bdf2fe4] {
  display: inline-flex;
}
.inspector-side-kit .side-kit-content .info-grid-area .mouse-n-click-information .coords-container .copy-icon[data-v-6bdf2fe4] {
  display: none;
  margin-right: 3px;
}
.inspector-side-kit .side-kit-content .info-grid-area .mouse-n-click-information .coords-container .coords-label[data-v-6bdf2fe4] {
  color: var(--font-color-secondary);
  font-size: 0.9em;
  margin-right: 3px;
}
.inspector-side-kit .side-kit-content .tree-area[data-v-6bdf2fe4] {
  display: flex;
  flex-direction: row;
  width: fit-content;
  flex-grow: 1;
}
.inspector-side-kit .snapshot-error-container[data-v-6bdf2fe4] {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  justify-content: center;
  align-items: center;
}
.inspector-side-kit .snapshot-error-container .error-message[data-v-6bdf2fe4] {
  color: var(--button-red);
}