.browser-edit[data-v-508dae7e] {
  display: flex;
  flex-direction: column;
}
.browser-edit .header[data-v-508dae7e] {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.browser-edit .body[data-v-508dae7e] {
  margin-top: 10px;
}